import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../Components/css/utilization.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { saveAs } from "file-saver";
import { Person, Person2Sharp } from "@mui/icons-material";

// import 'jspdf-autotable';
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
var currentDate = new Date();
function Utilization() {
  const [val, setval] = useState("");


  const [desarray, setdesarray] = useState([]);
  const [offshoreutilization,setoffshoreutilization] = useState([]);
  const [workshoputilizationw,setworkshoputilizationw] = useState([]);
  const [workshoputilizationt,setworkshoputilizationt] = useState([]);
  const [nationalityarray,setNationalityarray] = useState([]);
  const [Personarray,setPersonarray] = useState([]);
  const [Personarrayall,setPersonarrayall] = useState([]);
  const [fill, setfill] = useState("false");
 

  const navigate = useNavigate();
  const presentdate = new Date();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    const y = currentDate.getFullYear().toString();

    setval(y);

  }, []); // Empty dependency array to run the effect only once

  const monthList = [
    "01", // January
    "02", // February
    "03", // March
    "04", // April
    "05", // May
    "06", // June
    "07", // July
    "08", // August
    "09", // September
    "10", // October
    "11", // November
    "12", // December
  ];

  const handlevalChange2 = (event) => {
    const selectedval = event.target.value;

    setval(selectedval);
    setfill("false");
  };

  function objectToArray(obj) {
    return Object.keys(obj).map(key => ({ 'tech': obj[key] }));
}



const fetchProject = async () => {
  try {
    const res = await axios.get(
    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getutilizationoffshore?year=${val}`
    )
    const res1 = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getutilizationworkshopw?year=${val}`
    )
    const res3 = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getutilizationworkshopt?year=${val}`
    )
    const res2 = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details`
    );


  const resu = res.data.results;
  console.log("offshore utilization");
  console.log(resu);
  const resu1 = res1.data.results;
  console.log("Workshop utilization");
  console.log(resu1);
  const resu3 = res3.data.results;
  console.log("Workshop utilization travel");
  console.log(resu3);

  setoffshoreutilization(resu);
  setworkshoputilizationw(resu1);
  setworkshoputilizationt(resu3);
    const resu2unfiltered = res2.data;

    const resu2 = resu2unfiltered.filter(item => item.Designation !== '');



    const Nationalityarray = resu2.reduce((acc, obj) => {
      acc[obj.Customer_Name] = obj.Nationality;
      return acc;
    }, {});
    

    console.log("nationality", Nationalityarray);
    setNationalityarray(Nationalityarray);
    
    const Personarray = resu2.reduce((acc, obj) => {
      acc[obj.Customer_Name] = obj.Customer_Name;
      return acc;
    }, {});
    const personArrayf = objectToArray(Personarray);

    console.log("persons", personArrayf);
   setPersonarrayall(personArrayf);
  


    console.log(resu2);
    const designationArrays = resu2.reduce((acc, obj) => {
      acc[obj.Customer_Name] = obj.Designation;
      return acc;
    }, {});
    

setdesarray(designationArrays);
personArrayf.sort((a, b) => {
  if (a.tech < b.tech) {
      return -1;
  }
  if (a.tech > b.tech) {
      return 1;
  }
  return 0;
});
    if (searchQuery) {
      var filteredResults = personArrayf.filter((result) =>
        result.tech.toLowerCase().includes(searchQuery.toLowerCase())
      );
     
      setPersonarray(filteredResults);

    } else {

      setPersonarray(personArrayf);

    }
   
  } catch (error) {
    console.error("Error fetching project data:", error);
  }
};


  const handleSearchName = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  useEffect(() => {
    fetchProject();
  }, [searchQuery,  val]);

  const handledwn3 = () => {
    const form = document.getElementById("formm");
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  };


  function traveldays(name) {
    var offshore = 0, workshop = 0; // Corrected syntax
    var offshores = 0, workshops = 0;
    for (let i = 0; i < offshoreutilization.length; i++) {
      if (offshoreutilization[i].Tech === name) {
        offshore = parseInt(offshoreutilization[i].Travelday);
        offshores = parseInt(offshoreutilization[i].Standbyday)
      }
    }
    for (let i = 0; i < workshoputilizationt.length; i++) {
      if (workshoputilizationt[i].Tech === name) {
        workshop = parseInt(workshoputilizationt[i].Travelday);
        workshops = parseInt(workshoputilizationt[i].Standbyday);
      }
    }
    var ans = parseInt(offshore + workshop+workshops + offshores);
    // If no match is found, you can return a default value or handle it as per your requirement
    return ans; // Or any other default value
  }
function monthd(name,month){
  var offshore = 0, workshop = 0;
  
  switch(month) {
    case 1:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].January);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].January);
          break;
        }
      }
      break;
    case 2:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].February);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].February);
          break;
        }
      }
      break;
    case 3:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].March);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].March);
          break;
        }
      }
      break;
    case 4:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].April);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].April);
          break;
        }
      }
      break;
    case 5:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].May);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].May);
          break;
        }
      }
      break;
    case 6:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].June);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].June);
          break;
        }
      }
      break;
    case 7:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].July);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].July);
          break;
        }
      }
      break;
    case 8:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].August);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].August);
          break;
        }
      }
      break;
    case 9:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].September);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].September);
          break;
        }
      }
      break;
    case 10:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].October);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].October);
          break;
        }
      }
      break;
    case 11:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].November);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].November);
          break;
        }
      }
      break;
    case 12:
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop = parseInt(workshoputilizationw[i].December);
          break;
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore = parseInt(offshoreutilization[i].December);
          break;
        }
      }
      break;
    default:
      // Handle default case if necessary
      break;
  }
  
  var ans = parseInt(offshore + workshop);
  return ans;

}
function monthc(name){
  var offshore1 = 0, workshop1 = 0;
  var offshore2 = 0, workshop2 = 0;
  var offshore3 = 0, workshop3 = 0;
  var offshore4 = 0, workshop4 = 0;
  var offshore5 = 0, workshop5 = 0;
  var offshore6 = 0, workshop6 = 0;
  var offshore7 = 0, workshop7 = 0;
  var offshore8 = 0, workshop8 = 0;
  var offshore9 = 0, workshop9 = 0;
  var offshore10 = 0, workshop10 = 0;
  var offshore11 = 0, workshop11 = 0;
  var offshore12 = 0, workshop12 = 0;

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop1 = parseInt(workshoputilizationw[i].January);

        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore1 = parseInt(offshoreutilization[i].January);

        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop2 = parseInt(workshoputilizationw[i].February);

        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore2 = parseInt(offshoreutilization[i].February);
   
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop3 = parseInt(workshoputilizationw[i].March);

        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore3 = parseInt(offshoreutilization[i].March);
 
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop4 = parseInt(workshoputilizationw[i].April);
 
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore4 = parseInt(offshoreutilization[i].April);

        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop5 = parseInt(workshoputilizationw[i].May);
      
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore5 = parseInt(offshoreutilization[i].May);
  
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop6 = parseInt(workshoputilizationw[i].June);
   
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore6 = parseInt(offshoreutilization[i].June);
    
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop7 = parseInt(workshoputilizationw[i].July);
 
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore7 = parseInt(offshoreutilization[i].July);
     
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop8 = parseInt(workshoputilizationw[i].August);

        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore8 = parseInt(offshoreutilization[i].August);

        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop9 = parseInt(workshoputilizationw[i].September);

        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore9 = parseInt(offshoreutilization[i].September);
      
        }
      }
 
      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop10 = parseInt(workshoputilizationw[i].October);
     
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore10 = parseInt(offshoreutilization[i].October);
        
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop11 = parseInt(workshoputilizationw[i].November);
     
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore11 = parseInt(offshoreutilization[i].November);
       
        }
      }

      for (let i = 0; i < workshoputilizationw.length; i++) {
        if (workshoputilizationw[i].Tech === name) {
          workshop12 = parseInt(workshoputilizationw[i].December);
       
        }
      }
      for (let i = 0; i < offshoreutilization.length; i++) {
        if (offshoreutilization[i].Tech === name) {
          offshore12 = parseInt(offshoreutilization[i].December);
   
        }
      }

      // Handle default case if necessary


  
  var ans = parseInt(offshore1 + workshop1+offshore2 + workshop2+offshore3 + workshop3+offshore4 + workshop4+offshore5 + workshop5+offshore6 + workshop6+offshore7 + workshop7+offshore8 + workshop8+offshore9 + workshop9+offshore10 + workshop10+offshore11 + workshop11+offshore12 + workshop12);
  return ans;

}
function isLeapYear(year) {
  // Leap years are divisible by 4
  // Except for years divisible by 100
  // Unless the year is also divisible by 400

  if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)) {
      return true; // It's a leap year
  } else {
      return false; // It's not a leap year
  }
}
function workengagement(name){
  const w = monthc(name);
  var ans = w;
  const currentDate = new Date();
 const year = currentDate.getFullYear();
 if(year>val){
  if(isLeapYear(val)){
 ans=parseFloat(ans/366);
  }else{
   ans=parseFloat(ans/365);
  }
 }else{
   var daysInMonth = [31, isLeapYear(val) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
 
   var currentMonth = currentDate.getMonth();
   
   var currentDay = currentDate.getDate();
 
   var totalDays = parseInt(0);
   
   for (var i = 0; i < currentMonth; i++) {
       totalDays += parseInt(daysInMonth[i]);
   }
   
   // Add the current day of the month
   totalDays += parseInt(currentDay);
   ans = parseFloat(ans / totalDays);
 }
  ans = ans* 100;
  return ans.toFixed(2);
 }

 function setEngagementStyle(person) {
  const engagement = parseFloat(totalworkengagement(person.tech));
  if (engagement < 20) {
    return { backgroundColor: '#FF6A6A', color: 'white' };
  } else if (engagement > 60) {
    return { backgroundColor: 'rgb(67, 181, 129)', color: 'white' };
  } else {
    return { backgroundColor: '#D2CFF3', color:  '#567191'  };
  }
}


function totalworkengagement(name){
  const w = monthc(name);
  const t = traveldays(name);
  var ans = parseInt(w+t);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  if(year>val){
  if(isLeapYear(val)){
 ans=parseFloat(ans/366);
  }else{
   ans=parseFloat(ans/365);
  }
}else{
  var daysInMonth = [31, isLeapYear(val) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  var currentMonth = currentDate.getMonth();
  
  var currentDay = currentDate.getDate();

  var totalDays = 0;
  
  for (var i = 0; i < currentMonth; i++) {
      totalDays += parseInt(daysInMonth[i]);
  }
  
  // Add the current day of the month
  totalDays += parseInt(currentDay);
  ans =parseFloat(ans / totalDays);
}
  ans = ans*100;
  return ans.toFixed(2);
 }
function monthworked(month){

  switch(month) {
    case 1:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,1) > 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 2:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(isLeapYear(val)){
          if(monthd(Personarrayall[i].tech,2) > 14 ){
            monthval++;
          }
        }else{
          if(monthd(Personarrayall[i].tech,2) >= 14 ){
            monthval++;
          }
        }

      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 3:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,3) > 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 4:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,4) >= 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 5:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,5) > 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 6:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,6) >= 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 7:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,7) > 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 8:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,8) > 15){
          monthval++;
        }
      }
      var ans =(monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 9:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,9) >= 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 10:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,10) > 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 11:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,11) >= 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    case 12:
      var monthval =0;
      for(let i =0;i<Personarrayall.length;i++){
        if(monthd(Personarrayall[i].tech,12) > 15){
          monthval++;
        }
      }
      var ans = (monthval/Personarrayall.length)*100;
      return ans.toFixed(2);
      break;
    default:
      // Handle default case if necessary
      break;
  }


 }
 const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      whiteSpace: 'pre-wrap', // Allow content to break at any space or newline
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3, // Limit to 3 lines
      '-webkit-box-orient': 'vertical',
  },
}));


  
  

  return (
    <div>
      <div className={styles.vishdiv}>
        <div className={styles.drophead}>Filter:</div>
        <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>

          <div style={{ marginLeft: "20px" }}>
            <select
              className={styles.dropdown}
              onChange={handlevalChange2}
              value={val}
              id="year">
              <option value="2026" className={styles.dd}>
                2026
              </option>
              <option value="2025" className={styles.dd}>
                2025
              </option>
              <option value="2024" className={styles.dd}>
                2024
              </option>
              <option value="2023" className={styles.dd}>
                2023
              </option>
              <option value="2022" className={styles.dd}>
                2022
              </option>
              <option value="2021" className={styles.dd}>
                2021
              </option>
              <option value="2020" className={styles.dd}>
                2020
              </option>
            </select>
            <div
              style={{ position: "relative", display: "inline-block" }}
              onClick={() => {
                document.getElementById("year").click();
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  marginLeft: "160px",
                  marginTop: "-95px",
                  cursor: "pointer",
                }}>
                <path
                  d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                  fill="white"></path>
              </svg>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            justifyContent: "flex-start",
            marginLeft: "auto",
          }}>
          <div
            className={styles.searchContainer}
            style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Search by name"
              value={searchQuery}
              onChange={handleSearchName}
              className={styles.searchBox}
            />
            <svg
              className={styles.searchIconFilter}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#BCBCBC"
              />
              <path
                d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                fill="#BCBCBC"
              />
            </svg>
          </div>
        </div>
        <div className={styles.dwn} style={{ marginLeft: "auto" }}>

          {
            <svg
              width="40"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handledwn3}
              className={styles.btnfilter3}>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z"
                fill="#5B6BE1"
              />
            </svg>
          }
        </div>
      </div>

      <div>
        <form id="formm">
          <div
            style={{
              overflowY: "scroll",
              maxWidth: "97%",
              marginLeft: "1.5%",
            }}>
            {val !== "" && (
              <table className={styles.rigtable}>
                <thead>
                  <tr>
                    <th>S. No</th>
                    <th>Techs</th>
                    <th>Designation</th>
                    <th>Nationality</th>
                    <th>% Work engagement</th>
                    <th>% Total engagement</th>
                    <th style={{ whiteSpace:"nowrap"}} >Travel + Standby <br/> days</th>
                    <th>Jan</th>
                    <th>Feb</th>
                    <th>Mar</th>
                    <th>Apr</th>
                    <th>May</th>
                    <th>Jun</th>
                    <th>Jul</th>
                    <th>Aug</th>
                    <th>Sep</th>
                    <th>Oct</th>
                    <th>Nov</th>
                    <th>Dec</th>
    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td ><BootstrapTooltip title={"No. of technicians at work for more than 50% of the month"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                color="black"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                                style={{
                                    cursor: "pointer", marginLeft: "0%",
                                    marginTop: "-6%", backgroundColor: "white",
                                    borderRadius:"10px"
                                }}
                            >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                        </BootstrapTooltip></td>
                <td style={{ borderRight: 'none'}}></td>
                <td style={{ borderRight: 'none', borderLeft: 'none' }}></td>
                <td style={{ borderRight: 'none', borderLeft: 'none' }}></td>
                <td style={{ borderRight: 'none', borderLeft: 'none' }}></td>
                <td style={{ borderRight: 'none', borderLeft: 'none' }}></td>
                <td style={{  borderLeft: 'none' }}></td>

                        <td style={{  background: '#D2CFF3' }}>{monthworked(1)+'%'}</td>
                        <td style={{  background: '#D2CFF3'}}>{monthworked(2)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(3)+'%'}</td>

                      
                        <td style={{  background: '#D2CFF3' }}>{monthworked(4)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(5)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(6)+'%'}</td>

                       
                        <td style={{  background: '#D2CFF3' }}>{monthworked(7)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(8)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(9)+'%'}</td>

                        
                        <td style={{  background: '#D2CFF3'}}>{monthworked(10)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(11)+'%'}</td>
                        <td style={{  background: '#D2CFF3' }}>{monthworked(12)+'%'}</td>
                        </tr>
                        {Personarray.sort((a, b) => workengagement(a.tech) - workengagement(b.tech)).map((person, index) => {




                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{person.tech}</td>
                        <td>{desarray[person.tech]}</td>
                        <td>{nationalityarray[person.tech]}</td>

                        <td style={{  background: '#D2CFF3' }}>{workengagement(person.tech)}</td>
                        <td style={{ background: totalworkengagement(person.tech) < 20 ? '#dc143c' : '#D2CFF3',color: totalworkengagement(person.tech) < 20 ? 'white' : ''}}>{totalworkengagement(person.tech)}
                        </td>
     
                        <td >{traveldays(person.tech)}</td>
                        <td>{monthd(person.tech,1)}</td>
                        <td>{monthd(person.tech,2)}</td>    {/* January[person.Tech] */}
                        <td>{monthd(person.tech,3)}</td>      {/* February[person.Tech] */}

                     
                        <td>{monthd(person.tech,4)}</td>{/* March[person.Tech] */}
                        <td>{monthd(person.tech,5)}</td>{/* April[person.Tech] */}
                        <td>{monthd(person.tech,6)}</td>{/* May[person.Tech] */}

                        
                        <td>{monthd(person.tech,7)}</td>{/* June[person.Tech] */}
                        <td>{monthd(person.tech,8)}</td>{/* July[person.Tech] */}
                        <td>{monthd(person.tech,9)}</td>

                        
                        <td>{monthd(person.tech,10)}</td>
                        <td>{monthd(person.tech,11)}</td>
                        <td>{monthd(person.tech,12)}</td>

                       
                      </tr>
                    );
                  }
                // }
                  )}
                </tbody>
              </table>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Utilization;
