import React from "react";
import styles from "./css/workshopcreatenew.module.css";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ProjectDetailsWorkshop() {
  const [formdata1Display1, setFormData1Display1] = useState(true);
  const [isRigNameValid, setIsRigNameValid] = useState(true);
  const [workshop, setWorkshop] = useState(2);
  const { id } = useParams();
  const [projId, setProjId] = useState(0);
  const [projectList, setProjectList] = useState({});

  const workshopTab = (tabNumber) => {
    setWorkshop(tabNumber);
  };
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };
  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  }

  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  }
  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`)
  }
  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  const handleDivClick17 = () => {
    navigate(`/dispatch/${id}`);
  };
  const handleDivClick23 = () => {
    navigate(`/item-details-workshop/${id}`);
  };

  const [selectedFile, setSelectedFile] = useState({
    workPlan: null,
  });
  const [selectedFileName, setSelectedFileName] = useState({
    workplan: "",
  });

  const handleCancel = () => {
    setEditMode(false);
    setRefresh(!refresh);
  };

  const handleNumeriChange = (e) => {
    let { name, value } = e.target;

    // value = value.replace(/[^0-9]/g, "");
    // if (value.length <= 3 && !isNaN(value)) {
    setFormData1({
      ...formdata1,
      [name]: value,
    });
    // }
  };
  let projectId;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleUpdate = async (e) => {
    e.preventDefault();
    setEditMode(false);
    const {
      salesOrderNo,

      PONO,
      PODate,
      QuoteNO,
      QuoteDate,
      CostingID,
      ProjectName,
      Scope,
      CustomerName,
      FirstArrival,
      EstimatedDateCommencement,
      TargetDaysForCompletion,
      TargetManhours,
      opsEngineer,
      currentStatus,
      projectDescription,
      quantity,
      scopeDescription,
      Design,
      DmccFze,
      FzeDmccPo,
    } = formdata1;

    const payload = {
      Sales_Order_No: salesOrderNo,
      PO_No: PONO,
      PO_Date: PODate,
      Quote_No: QuoteNO,
      Quote_Date: QuoteDate,
      Costing_ID: CostingID,
      Project_Name: ProjectName,
      Scope: Scope,
      Quantity: quantity,
      Customer_Name: CustomerName,
      First_Arrival: FirstArrival,
      Estimated_Date_Of_Commencement: EstimatedDateCommencement,
      Target_Days_For_Completion: TargetDaysForCompletion,
      Target_Manhours: TargetManhours,
      Ops_Engineer: opsEngineer,
      Current_Status: currentStatus,
      Project_Description: projectDescription,
      Scope_Description: scopeDescription,
      Design: Design,
      Dmcc_fze: DmccFze,
      Fze_dmcc_po: FzeDmccPo,
    };
    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopprojectdetails_put/${id}`,
        payload
      );

      if (res.status === 200) {
        alert("project updated successfully");
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("DD/MM/YYYY");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };

  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);
  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          Project Name: {formdata1.ProjectName}
        </label>
        <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
        <label className={styles.aryl}>
          Project Description:{formdata1.projectDescription}
        </label>
        <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
        <label className={styles.aryl}>
          Target Days:{formdata1.TargetDaysForCompletion}
        </label>
        <label className={styles.aryl}>
          Target Manhours:{formdata1.TargetManhours}
        </label>
      </div>
      <div className={styles.aryTabs}>
        <div
          className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Project Checklist
        </div>
        <div
          className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Project Details
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick10}
        >
          Team Details
        </div>
        <div
          className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick12}>
          Inventory Used
        </div>
        <div
          className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick13}>
          Purchases
        </div>
        <div
          className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick2}>
          Timesheet
        </div>
        <div
          className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick3}>
          Deliveries
        </div>
        <div
            className={workshop === 23 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick23}>
            Serial Number
          </div>
          <div
            className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick15}>
            Production
          </div>
        {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
        <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>
        {/* <div
          className={workshop === 17 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick17}>
          Dispatch
        </div> */}
        <div
          className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick5}>
          Outsourcing
        </div>
        <div
          className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick6}>
          Reports
        </div>
        <div
          className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick7}>
          Inspections
        </div>
        <div
          className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick8}>
          Items Received
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick9}>
          Finished goods
        </div>
        <div
          className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick16}
        >
          WIP
        </div>
      </div>
      {workshop === 1 && <></>}
      {workshop === 2 && <></>}
      {workshop === 3 && <></>}
      {workshop === 4 && <></>}
      {workshop === 5 && <></>}
      {workshop === 6 && <></>}
      {workshop === 7 && <></>}
      {workshop === 8 && <></>}
      {workshop === 9 && <></>}
      {formdata1Display1 && (
        <div className={styles.sections}>
          <form>
            <div className={styles.splitHalfSection}>
              <div style={{
                marginTop: "70px",
              }}>
                <div className={styles.formElement}>
                  <label>Sales Order No</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {editMode ? (
                      <input
                        type="text"
                        value={formdata1.salesOrderNo}
                        name="salesOrderNo"
                        onChange={handleNumeriChange}
                      // className={styles.textbox1}
                      />
                    ) : (
                      <input
                        type="text"
                        value={formdata1.salesOrderNo}
                        name="salesOrderNo"
                        onChange={handleNumeriChange}
                        className={styles.textbox1}
                        disabled
                        readOnly
                      />
                    )}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <label>PO Number</label>
                  {editMode ? (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.PONO}
                      name="PONO"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.PONO}
                      name="PONO"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>PO Date</label>
                  {editMode ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="PODate"
                        value={
                          formdata1.PODate
                            ? dayjs(formdata1.PODate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("PODate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="PODate"
                        value={
                          formdata1.PODate
                            ? dayjs(formdata1.PODate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("PODate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        className={styles.textbox2}
                        disabled
                        readOnly
                      />
                    </LocalizationProvider>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Quote no</label>
                  {editMode ? (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.QuoteNO}
                      name="QuoteNO"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.QuoteNO}
                      name="QuoteNO"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Quote Date</label>
                  {editMode ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="QuoteDate"
                        value={
                          formdata1.QuoteDate
                            ? dayjs(formdata1.QuoteDate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("QuoteDate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="QuoteDate"
                        value={
                          formdata1.QuoteDate
                            ? dayjs(formdata1.QuoteDate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("QuoteDate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        className={styles.textbox2}
                        disabled
                        readOnly
                      />
                    </LocalizationProvider>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Costing ID</label>
                  {editMode ? (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.CostingID}
                      name="CostingID"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.CostingID}
                      name="CostingID"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Customer Name</label>
                  {editMode ? (
                    <>
                      <select
                        value={formdata1.CustomerName}
                        name="CustomerName"
                        onChange={handleOnChange}
                        required>
                        <option value="">Select value</option>
                        <option value="Shelf Drilling">Shelf Drilling</option>
                        <option value="Valaris">Valaris</option>
                        <option value="ARO Drilling">ARO Drilling</option>
                        <option value="ADES">ADES</option>
                        <option value="COC">COC</option>
                        <option value="Northern Offshore">Northern Offshore</option>
                        <option value="Melius">Melius</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <select
                        value={formdata1.CustomerName}
                        name="CustomerName"
                        onChange={handleOnChange}
                        required
                        className={styles.textbox1}
                        disabled
                        readOnly>
                        <option value="">Select value</option>
                        <option value="Shelf Drilling">Shelf Drilling</option>
                        <option value="Valaris">Valaris</option>
                        <option value="ARO Drilling">ARO Drilling</option>
                        <option value="ADES">ADES</option>
                        <option value="COC">COC</option>
                        <option value="Northern Offshore">Northern Offshore</option>
                        <option value="Melius">Melius</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  )}
                </div>

                <div className={styles.formElement}>
                  {editMode ? (
                    <>
                      <label style={{ marginBottom: "-84px" }}>DMCC-FZE Quote</label>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "-92px" }}>
                        {/* <span className={styles.salesOrderSpan}></span> */}
                        <input
                          type="text"
                          value={formdata1.DmccFze}
                          name="DmccFze"
                          onChange={handleNumeriChange}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <label style={{ marginBottom: "-84px" }}>DMCC-FZE Quote</label>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "-92px" }}>
                        {/* <span className={styles.salesOrderSpan}></span> */}
                        <input
                          type="text"
                          value={formdata1.DmccFze}
                          name="DmccFze"
                          onChange={handleNumeriChange}
                          className={styles.textbox1}
                          disabled
                          readOnly
                        />
                      </div>
                    </>
                  )}
                </div>


                <div className={styles.formElement}>
                  {editMode ? (
                    <>
                      <label style={{ marginBottom: "-184px" }}>FZE-DMCC PO</label>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "-182px" }}>
                        {/* <span className={styles.salesOrderSpan}></span> */}
                        <input
                          type="text"
                          value={formdata1.FzeDmccPo}
                          name="FzeDmccPo"
                          onChange={handleNumeriChange}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <label style={{ marginBottom: "-184px" }}>FZE-DMCC PO</label>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "-182px" }}>
                        {/* <span className={styles.salesOrderSpan}></span> */}
                        <input
                          type="text"
                          value={formdata1.FzeDmccPo}
                          name="FzeDmccPo"
                          onChange={handleNumeriChange}
                          className={styles.textbox1}
                          disabled
                          readOnly
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div style={{
                marginTop: "70px",
              }}>
                <div className={styles.formElement}>
                  {editMode ? (
                    <>
                      <label>Design</label>
                      <select
                        value={formdata1.Design}
                        name="Design"
                        onChange={handleOnChange}
                        required
                      //className={!isRigNameValid ?styles.highlightError : ''}
                      >
                        <option value="">Select value</option>
                        <option value="MLT">MLT</option>
                        <option value="BMC">BMC</option>
                        <option value="ZPMC">ZPMC</option>
                        <option value="NoV">NoV</option>
                        <option value="Keppel">Keppel</option>
                        <option value="Fairfield">Fairfield</option>
                        <option value="Promoter">Promoter</option>
                        {/* {duplicateRemovedShortName.map((short_name) => (
                        <option key={short_name}>{short_name}</option>
                      ))} */}
                      </select>

                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <label>Design</label>
                      <select
                        value={formdata1.Design}
                        name="Design  "
                        onChange={handleOnChange}
                        required
                        disabled
                        readOnly
                        //className={!isRigNameValid ?styles.highlightError : ''}
                        className={styles.textbox1}>
                        <option value="">Select value</option>
                        <option value="MLT">MLT</option>
                        <option value="BMC">BMC</option>
                        <option value="ZPMC">ZPMC</option>
                        <option value="NoV">NoV</option>
                        <option value="Keppel">Keppel</option>
                        <option value="Fairfield">Fairfield</option>
                        <option value="Promoter">Promoter</option>
                        {/* {duplicateRemovedShortName.map((short_name) => (
                        <option key={short_name}>{short_name}</option>
                      ))} */}
                      </select>

                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  )}
                </div>
                <div className={styles.formElement}>
                  {editMode ? (
                    <>
                      <label>Project Name *</label>
                      <select
                        value={formdata1.ProjectName}
                        name="ProjectName"
                        onChange={handleOnChange}
                        required
                      //className={!isRigNameValid ?styles.highlightError : ''}
                      >
                        <option value="">Select value</option>
                        <option value="Skidder">Skidder</option>
                        <option value="Anchor Winch">Anchor Winch</option>
                        <option value="Jacking Primary Gearbox">Jacking Primary Gearbox</option>
                        <option value="Jacking Gearbox">Jacking Gearbox</option>
                        <option value="Skidding Primary Gearbox">Skidding Primary Gearbox</option>
                        <option value="Crane Primary Gearbox">Crane Primary Gearbox</option>
                        <option value="Jacking Toolbox">Jacking Toolbox</option>
                        <option value="RWT Gearbox">RWT Gearbox</option>
                        <option value="CycloDrive">CycloDrive</option>
                        <option value="N-Driver">N-Driver</option>
                        <option value="Mud Pumps">Mud Pumps</option>
                        <option value="Spares Trading">Spares Trading</option>
                        

                        {/* {duplicateRemovedShortName.map((short_name) => (
                        <option key={short_name}>{short_name}</option>
                      ))} */}
                      </select>

                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <label>Project Name *</label>
                      <select
                        value={formdata1.ProjectName}
                        name="ProjectName"
                        onChange={handleOnChange}
                        required
                        disabled
                        readOnly
                        //className={!isRigNameValid ?styles.highlightError : ''}
                        className={styles.textbox1}>
                          <option value="">Select value</option>
                          <option value="Skidder">Skidder</option>
                          <option value="Anchor Winch">Anchor Winch</option>
                          <option value="Jacking Primary Gearbox">Jacking Primary Gearbox</option>
                          <option value="Jacking Gearbox">Jacking Gearbox</option>
                          <option value="Skidding Primary Gearbox">Skidding Primary Gearbox</option>
                          <option value="Crane Primary Gearbox">Crane Primary Gearbox</option>
                          <option value="Jacking Toolbox">Jacking Toolbox</option>
                          <option value="RWT Gearbox">RWT Gearbox</option>
                          <option value="CycloDrive">CycloDrive</option>
                          <option value="N-Driver">N-Driver</option>
                          <option value="Mud Pumps">Mud Pumps</option>
                          <option value="Spares Trading">Spares Trading</option>
                        {/* {duplicateRemovedShortName.map((short_name) => (
                        <option key={short_name}>{short_name}</option>
                      ))} */}
                      </select>

                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Scope</label>
                  {editMode ? (
                    <>
                      <select
                        value={formdata1.Scope}
                        name="Scope"
                        onChange={handleOnChange}
                        required>
                        <option value="">Select value</option>
                        <option value="Assembly">Assembly</option>
                        <option value="D&I">D&I</option>
                        <option value="Overhaul">Overhaul</option>
                        <option value="Replenishment">Replenishment</option>
                        <option value="Inspection">Inspection</option>
                        <option value="Storage">Storage</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <select
                        value={formdata1.Scope}
                        name="Scope"
                        onChange={handleOnChange}
                        required
                        className={styles.textbox1}
                        disabled
                        readOnly>
                        <option value="">Select value</option>
                        <option value="Assembly">Assembly</option>
                        <option value="D&I">D&I</option>
                        <option value="Overhaul">Overhaul</option>
                        <option value="Replenishment">Replenishment</option>
                        <option value="Inspection">Inspection</option>
                        <option value="Storage">Storage</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Project Description</label>
                  {editMode ? (
                    <input
                      type="text"

                      value={formdata1.projectDescription}
                      name="projectDescription"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="text"

                      value={formdata1.projectDescription}
                      name="projectDescription"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Quantity</label>
                  {editMode ? (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.quantity}
                      name="quantity"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.quantity}
                      name="quantity"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Date of First Component/Spares arrival</label>
                  {editMode ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="FirstArrival"
                        value={
                          formdata1.FirstArrival
                            ? dayjs(formdata1.FirstArrival, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("FirstArrival", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        required
                      />
                    </LocalizationProvider>
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="FirstArrival"
                        value={
                          formdata1.FirstArrival
                            ? dayjs(formdata1.FirstArrival, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("FirstArrival", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        required
                        disabled
                        readOnly
                        className={styles.textbox2}
                      />
                    </LocalizationProvider>
                  )}
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Estimated Date Of Commencement</label>
                  {editMode ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="EstimatedDateCommencement"
                        value={
                          formdata1.EstimatedDateCommencement
                            ? dayjs(
                              formdata1.EstimatedDateCommencement,
                              "DD/MM/YYYY"
                            )
                            : null
                        }
                        onChange={(value) =>
                          handleDates("EstimatedDateCommencement", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        required
                      />
                    </LocalizationProvider>
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="EstimatedDateCommencement"
                        value={
                          formdata1.EstimatedDateCommencement
                            ? dayjs(
                              formdata1.EstimatedDateCommencement,
                              "DD/MM/YYYY"
                            )
                            : null
                        }
                        onChange={(value) =>
                          handleDates("EstimatedDateCommencement", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        required
                        disabled
                        readOnly
                        className={styles.textbox2}
                      />
                    </LocalizationProvider>
                  )}
                </div>
              </div>

              <div className={styles.btnContainer}>
                {/* <BasicModal  /> */}
                {/* <Button
            onClick={handleOpen}
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              fontWeight: "700",
              border: "1px solid #5B6BE1",
              color: "#5B6BE1",
              borderRadius: "8px",
              height: "50px",
              width: "170px",
              "&:hover": {
                borderColor: "#5B6BE1",
              },
            }}>
            Discard
          </Button>
                <Button
                    onClick={handleCreateNewProject}
                    variant="contained"
                    className={styles.proceedBtn}
               >
                    Create Project
                  </Button> */}
                {editMode ? (
                  <div style={{
                    display: "flex", marginBottom: "85px"
                  }}>
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      className={styles.cancelBtn}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      className={styles.saveButton}>
                      Save Changes
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      className={styles.previewAndDownloadBtn}
                      variant="contained"
                      onClick={() => setEditMode(true)}>
                      Edit
                    </Button>
                  </div>
                )}
              </div>
              <div style={{
                marginTop: "70px",
              }}>
                <div className={styles.formElement}>
                  <label>Target Days For Completion</label>
                  {editMode ? (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.TargetDaysForCompletion}
                      name="TargetDaysForCompletion"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.TargetDaysForCompletion}
                      name="TargetDaysForCompletion"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Target Manhours</label>
                  {editMode ? (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.TargetManhours}
                      name="TargetManhours"
                      onChange={handleOnChange}
                    />
                  ) : (
                    <input
                      type="number"
                      min={0}
                      value={formdata1.TargetManhours}
                      name="TargetManhours"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                      disabled
                      readOnly
                    />
                  )}
                </div>
                <div className={styles.formElement}>
                  <label>Ops Engineer</label>
                  {editMode ? (
                    <>
                      <select
                        value={formdata1.opsEngineer}
                        name="opsEngineer"
                        onChange={handleOnChange}>
                        <option value=""> Select the Member</option>
                        <option value="Jancy">Jancy</option>
                        <option value="Charulatha">Charulatha</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <select
                        value={formdata1.opsEngineer}
                        name="opsEngineer"
                        onChange={handleOnChange}
                        className={styles.textbox1}
                        disabled
                        readOnly>
                        <option value=""> Select the Member</option>
                        <option value="Jancy">Jancy</option>
                        <option value="Charulatha">Charulatha</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Scope Description</label>
                  {editMode ? (
                    <textarea
                      type="text"
                      min={0}
                      value={formdata1.scopeDescription}
                      name="scopeDescription"
                      onChange={handleOnChange}
                      className={styles.textbox1b}
                    />
                  ) : (
                    <textarea
                      type="text"
                      min={0}
                      value={formdata1.scopeDescription}
                      name="ScopeDescription"
                      onChange={handleOnChange}
                      className={styles.textbox1a}
                      disabled
                      readOnly
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Current Status</label>
                  {editMode ? (
                    <textarea
                      type="text"
                      min={0}
                      value={formdata1.currentStatus}
                      name="currentStatus"
                      onChange={handleOnChange}
                      className={styles.textbox1b}
                    />
                  ) : (
                    <textarea
                      type="text"
                      min={0}
                      value={formdata1.currentStatus}
                      name="currentStatus"
                      onChange={handleOnChange}
                      className={styles.textbox1a}
                      disabled
                      readOnly
                    />
                  )}
                </div>
              </div>
            </div>

          </form>
        </div>
      )}
    </div>
  );
}

export default ProjectDetailsWorkshop;
