import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../Components/css/yearmandays.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { saveAs } from "file-saver";

// import 'jspdf-autotable';
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

function Yearmandays() {
  const [val, setval] = useState("");

  const [mandaysjan, setmandaysjan] = useState("");
  const [mandaysfeb, setmandaysfeb] = useState("");
  const [mandaysmar, setmandaysmar] = useState("");
  const [mandaysapr, setmandaysapr] = useState("");
  const [mandaysmay, setmandaysmay] = useState("");
  const [mandaysjun, setmandaysjun] = useState("");
  const [mandaysjul, setmandaysjul] = useState("");
  const [mandaysaug, setmandaysaug] = useState("");
  const [mandayssep, setmandayssep] = useState("");
  const [mandaysoct, setmandaysoct] = useState("");
  const [mandaysnov, setmandaysnov] = useState("");
  const [mandaysdec, setmandaysdec] = useState("");

  const [desarray, setdesarray] = useState([]);

  const [fill, setfill] = useState("false");
  const [Tracker, setTracker] = useState(2);

  const navigate = useNavigate();
  const presentdate = new Date();

  useEffect(() => {
    async function a (){
        const currentDate = new Date();
        const y = currentDate.getFullYear().toString();
       await setval(y);
    }

 a();
  }, []); // Empty dependency array to run the effect only once

  const monthList = [
    "01", // January
    "02", // February
    "03", // March
    "04", // April
    "05", // May
    "06", // June
    "07", // July
    "08", // August
    "09", // September
    "10", // October
    "11", // November
    "12", // December
  ];

  const handlevalChange2 = (event) => {
    const selectedval = event.target.value;

    setval(selectedval);
    setfill("false");
  };
  const TrackerTab = (tabNumber) => {
    setTracker(tabNumber);
    if(tabNumber === 1){
      navigate('/monthmandays');
    } else if (tabNumber === 2){
      navigate('/yearmandays');
    }
  };



  const fetchProject0 = async () => {
    var valmonth=0;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysjan(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject1 = async () => {
    var valmonth=1;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysfeb(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject2 = async () => {
    var valmonth=2;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysmar(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject3 = async () => {
    var valmonth=3;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysapr(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject4 = async () => {
    var valmonth=4;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysmay(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject5 = async () => {
    var valmonth=5;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysjun(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject6 = async () => {
    var valmonth=6;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseInt(rig.Mandays), 0);
  setmandaysjul(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject7 = async () => {
    var valmonth=7;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysaug(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject8 = async () => {
    var valmonth=8;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandayssep(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject9 = async () => {
    var valmonth=9;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysoct(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject10 = async () => {
    var valmonth=10;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysnov(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const fetchProject11 = async () => {
    var valmonth=11;
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
      );
  
    //   const res3 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    //   );
  
    //   const res4 = await axios.get(
    //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    //   );
  
      const resu = res.data.results;
    //   const resu1 = res3.data.results;
    //    const resu3 = res4.data.results;
  
      console.log("Resu - Offshore");
      console.log(resu);
  
  
//       console.log("Resu1 - Workshop");
  
//       var transformedArray1 =[];
//       transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//         Rig_Name,
//         SO_No: '-',  
//         Mandays
//     }));
//     console.log(transformedArray1);
  
  
//        console.log("Resu3 - Workshop Travel");
//        var transformedArray2 =[];
  
//     transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  // Add SO_No with value '-'
//       Mandays
//   }));
//   console.log(transformedArray2);
//   const combinedArray = transformedArray1.concat(transformedArray2);
//   const result = combinedArray.reduce((acc, item) => {
//     // Create a unique key for each combination of Rigname and SO_No
//     const key = `${item.Rigname}-${item.SO_No}`;
  
//     // If the key doesn't exist, create a new entry in the accumulator
//     if (!acc[key]) {
//         acc[key] = { ...item };  // Copy the current item
//     } else {
//         // If the key exists, add the Mandays
//         acc[key].Mandays += item.Mandays;
//     }
//     return acc;
//   }, {});
//   const finalArray = Object.values(result);
  const concatenatedArray = [
    ...(resu ),
    // ...(finalArray)
    
  ];
  concatenatedArray.sort((a, b) => {
    // Comparing string values of Rigname
    if (a.Rig_Name < b.Rig_Name) {
        return -1; // a comes first
    }
    if (a.Rig_Name > b.Rig_Name) {
        return 1; // b comes first
    }
    return 0; // a and b are equal
  });

  const totalMandays = concatenatedArray.reduce((total, rig) => total + parseFloat(rig.Mandays), 0);
  setmandaysdec(totalMandays);
  } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    if(val){
    fetchProject0();
    fetchProject1();
    fetchProject2();
    fetchProject3();
    fetchProject4();
    fetchProject5();
    fetchProject6();
    fetchProject7();
    fetchProject8();
    fetchProject9();
    fetchProject10();
    fetchProject11();
    }
  }, [ val]);

  const handledwn3 = () => {
    const form = document.getElementById("formm");
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  };

  const dataa = [
    { month: "Jan", mandays: mandaysjan },
    { month: "Feb", mandays: mandaysfeb },
    { month: "Mar", mandays: mandaysmar },
    { month: "Apr", mandays: mandaysapr },
    { month: "May", mandays: mandaysmay },
    { month: "Jun", mandays: mandaysjun },
    { month: "Jul", mandays: mandaysjul },
    { month: "Aug", mandays: mandaysaug },
    { month: "Sep", mandays: mandayssep },
    { month: "Oct", mandays: mandaysoct },
    { month: "Nov", mandays: mandaysnov },
    { month: "Dec", mandays: mandaysdec }
];

 

  return (
    <div>
      <div className={styles.vishdiv}>
      {Tracker === 2 && (
          <>
        <div
          style={{
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            marginLeft: "20px",
            marginTop:"17.5px"
          }}
          className={
            Tracker === 1 ? styles.aryaTabs1 : styles.aryaTabs2
          }
          onClick={() => TrackerTab(1)}>
          Monthly
        </div>
        <div
          style={{
            borderBottomRightRadius: "10px",
            borderTopRightRadius: "10px",
            marginLeft: "-16px",
            marginTop:"17.5px"
          }}
          className={
           Tracker === 2 ? styles.aryaTabs1 : styles.aryaTabs2
          }
          onClick={() => TrackerTab(2)}>
          Yearly
        </div>

          </>
        )}
        <div className={styles.drophead}>Filter:</div>
        <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>

          <div style={{ marginLeft: "20px" }}>
            <select
              className={styles.dropdown}
              onChange={handlevalChange2}
              value={val}
              id="year">
              <option value="2026" className={styles.dd}>
                2026
              </option>
              <option value="2025" className={styles.dd}>
                2025
              </option>
              <option value="2024" className={styles.dd}>
                2024
              </option>
              <option value="2023" className={styles.dd}>
                2023
              </option>
              <option value="2022" className={styles.dd}>
                2022
              </option>
              <option value="2021" className={styles.dd}>
                2021
              </option>
              <option value="2020" className={styles.dd}>
                2020
              </option>
            </select>
            <div
              style={{ position: "relative", display: "inline-block" }}
              onClick={() => {
                document.getElementById("year").click();
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  marginLeft: "160px",
                  marginTop: "-95px",
                  cursor: "pointer",
                }}>
                <path
                  d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                  fill="white"></path>
              </svg>
            </div>
          </div>
        </div>



        <div className={styles.dwn} style={{ marginLeft: "auto" }}>
          {/* <button onClick={handledwn3} className={styles.excelbtn}>
            Download 
          </button> */}
          {
            <svg
              width="40"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handledwn3}
              className={styles.btnfilter3}>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z"
                fill="#5B6BE1"
              />
            </svg>
          }
        </div>
      </div>
<div className={styles.flexContainer} >
      <div style={{width: "40vw"}}>
        <form id="formm">
          <div
            style={{
              overflowY: "scroll",
              maxWidth: "97%",
              marginLeft: "1.5%",
              marginTop: "1.5%"
            }}>
            {val !== "" && (
              <table className={styles.rigtable}>
                <thead>
                  <tr>
                    <th>S. No</th>
                    <th>Month</th>
                    <th>Mandays</th>

                  </tr>
                </thead>
                <tbody>
  

  
                      <tr >
                        <td>1</td>
                        <td>January</td>
                        <td>{mandaysjan}</td>
                      
                      </tr>
                      <tr >
                        <td>2</td>
                        <td>February</td>
                        <td>{mandaysfeb}</td>
                      
                      </tr>
                      <tr >
                        <td>3</td>
                        <td>March</td>
                        <td>{mandaysmar}</td>
                      
                      </tr>
                      <tr >
                        <td>4</td>
                        <td>April</td>
                        <td>{mandaysapr}</td>
                      
                      </tr>
                      <tr >
                        <td>5</td>
                        <td>May</td>
                        <td>{mandaysmay}</td>
                      
                      </tr>
                      <tr >
                        <td>6</td>
                        <td>June</td>
                        <td>{mandaysjun}</td>
                      
                      </tr>
                      <tr >
                        <td>7</td>
                        <td>July</td>
                        <td>{mandaysjul}</td>
                      
                      </tr>
                      <tr >
                        <td>8</td>
                        <td>August</td>
                        <td>{mandaysaug}</td>
                      
                      </tr>
                      <tr >
                        <td>9</td>
                        <td>September</td>
                        <td>{mandayssep}</td>
                      
                      </tr>
                      <tr >
                        <td>10</td>
                        <td>October</td>
                        <td>{mandaysoct}</td>
                      
                      </tr>
                      <tr >
                        <td>11</td>
                        <td>November</td>
                        <td>{mandaysnov}</td>
                      
                      </tr>
                      <tr >
                        <td>12</td>
                        <td>December</td>
                        <td>{mandaysdec}</td>
                      
                      </tr>
            

                </tbody>
              </table>
            )}
          </div>
        </form>
      </div>
<div style={{ marginTop: "1%", width: '60%', display: "flex", alignItems: "center" }}>
            <ResponsiveContainer   aspect={2}>
                <LineChart data={dataa} margin={{ right: 100 }}>
                    <CartesianGrid />
                    <XAxis dataKey="month" interval={"preserveStartEnd"} />
                    <YAxis></YAxis>
                    <Legend />
                    <Tooltip />
                    <Line
                        dataKey="mandays"
                        stroke="#5B6BE1"
                        activeDot={{ r: 8 }}
                    />
  
                </LineChart>
            </ResponsiveContainer>
            </div>
    </div>
    </div>
  );
}

export default Yearmandays;
