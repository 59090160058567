import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../Components/css/availability.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";

import { saveAs } from "file-saver";

// import 'jspdf-autotable';
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
var currentDate = new Date();
function Attendance() {
    const [val, setval] = useState("");
    const [valmonth, setvalmonth] = useState("");
    const [projectdetailso, setprojectdetailso] = useState([]);
    const [projectdetailsw, setprojectdetailsw] = useState([]);
    const [resarray, setresarray] = useState([]);
    const [desarray, setdesarray] = useState([]);
    const [retainerarray, setRetainerarray] = useState([]);
    const [fill, setfill] = useState("false");


    const navigate = useNavigate();
    const presentdate = new Date();
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(() => {
        const currentDate = new Date();
        const y = currentDate.getFullYear().toString();
        const m = currentDate.getMonth().toString();
        setval(y);
        setvalmonth(m);
    }, []); // Empty dependency array to run the effect only once

    const monthList = [
        "01", // January
        "02", // February
        "03", // March
        "04", // April
        "05", // May
        "06", // June
        "07", // July
        "08", // August
        "09", // September
        "10", // October
        "11", // November
        "12", // December
    ];
    const handlevalChange = (event) => {
        const selectedval = event.target.value;

        setvalmonth(selectedval);

        setfill("false");
    };
    const handlevalChange2 = (event) => {
        const selectedval = event.target.value;

        setval(selectedval);
        setfill("false");
    };



    const fetchProject = async () => {
        try {
            const res = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getattendancedetailso?month=${monthList[valmonth]}&year=${val}`
            );

            const res3 = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getattendancedetailsw?month=${monthList[valmonth]}&year=${val}`
            );

            const res2 = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details`
            );

            const resu = res.data.results;
            const resu1 = res3.data.results;
            const resu2 = res2.data;

            console.log("Resu - Offshore");
            console.log(resu);
            console.log("Resu1 - Workshop");
            console.log(resu1);

            const resultArray = resu.map((el) => el.Tech);
            const resultArray1 = resu1.map((el) => el.Tech);

            const retainershipArrays = resu2.reduce((acc, obj) => {
                acc[obj.Customer_Name] = obj.Retainership;
                return acc;
            }, {});




            // const retainershipArrays = {};
            // resultArray.forEach((name) => {
            //   const retainerships = resu2
            //     .filter((item) => item.Customer_Name.toLowerCase() === name.toLowerCase())
            //     .map((item) => item.Retainership);
            //   retainershipArrays[name] = retainerships[0];
            // });

            // resultArray1.forEach((name) => {
            //   const retainerships = resu2
            //     .filter((item) => item.Customer_Name.toLowerCase() === name.toLowerCase())
            //     .map((item) => item.Retainership);
            //   retainershipArrays[name] = retainerships[0];
            // });

            console.log("retainershipArray", retainershipArrays);
            setRetainerarray(retainershipArrays);


            console.log("resultarrayyyyyy", resultArray)
            console.log("resultarray1", resultArray1)

            console.log(resu2);
            const designationArrays = resu2.reduce((acc, obj) => {
                acc[obj.Customer_Name] = obj.Designation;
                return acc;
            }, {});




            const designationArrays1 = {};
            resultArray.forEach((name) => {
                const designations = resu2
                    .filter((item) => item.Customer_Name.toLowerCase() === name.toLowerCase())
                    .map((item) => item.Designation);
                designationArrays1[name] = designations[0];
            });

            resultArray1.forEach((name) => {
                const designations = resu2
                    .filter((item) => item.Customer_Name.toLowerCase() === name.toLowerCase())
                    .map((item) => item.Designation);
                designationArrays1[name] = designations[0];
            });

            Object.keys(designationArrays1).forEach(key => {
                if (designationArrays1[key] === undefined || designationArrays1[key] === "") {
                    delete designationArrays1[key];
                }
            });
            Object.keys(designationArrays).forEach(key => {
                if (designationArrays[key] === undefined || designationArrays[key] === "") {
                    delete designationArrays[key];
                }
            });
            console.log("Designation Array");
            console.log(designationArrays);
            setdesarray(designationArrays);

            let namesArrayall = [];

            for (let name in designationArrays) {
                if (designationArrays.hasOwnProperty(name)) {
                    namesArrayall.push(name);
                }
            }
            console.log("Name Array all");
            console.log(namesArrayall);

            let namesArraytokeep = [];

            for (let name in designationArrays1) {
                if (designationArrays1.hasOwnProperty(name)) {
                    namesArraytokeep.push(name);
                }
            }
            console.log("Name Array to keep");
            console.log(namesArraytokeep);
            const namesarraytoadd = namesArrayall.filter(name => !namesArraytokeep.includes(name));
            console.log("Name Array to add");
            console.log(namesarraytoadd);

            namesarraytoadd.forEach(name => {
                let personObj = { Tech: name };

                // Initialize days to "-"
                for (let i = 1; i <= 31; i++) {
                    personObj['Day' + i] = "-";
                }

                // Add the person object to the result array
                resu.push(personObj);
            });

            if (searchQuery) {
                var filteredResults = resu.filter((result) =>
                    result.Tech.toLowerCase().includes(searchQuery.toLowerCase())
                );
                var filteredResults1 = resu1.filter((result) =>
                    result.Tech.toLowerCase().includes(searchQuery.toLowerCase())
                );
                var f1 = filteredResults.filter(obj => designationArrays.hasOwnProperty(obj.Tech));
                var f2 = filteredResults1.filter(obj => designationArrays.hasOwnProperty(obj.Tech));
                setprojectdetailso(f1);
                setprojectdetailsw(f2);
            } else {
                var fo1 = resu.filter(obj => designationArrays.hasOwnProperty(obj.Tech));
                var fo2 = resu1.filter(obj => designationArrays.hasOwnProperty(obj.Tech));
                setprojectdetailso(fo1);
                setprojectdetailsw(fo2);
            }

        } catch (error) {
            console.error("Error fetching project data:", error);
        }
    };


    const handleSearchName = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
    };

    useEffect(() => {
        fetchProject();
    }, [searchQuery, valmonth, val]);

    const handledwn3 = () => {
        const form = document.getElementById("formm");
        const table = form.querySelector("table");
        const rows = Array.from(table.querySelectorAll("tr"));

        const data = rows.map((row) =>
            Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
        );

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Use XLSX.write to generate the Excel file as an ArrayBuffer
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        // Convert the ArrayBuffer to a Blob
        const blob = new Blob([wbout], { type: "application/octet-stream" });

        // Use FileSaver.js to save the file
        saveAs(blob, "table_data.xlsx");
    };

    // const handlefromdate=(event)=>{
    //   setfromdate(event.target.value);
    //       console.log("I am here1");
    //   console.log(fromdate);
    //   //2024-03-06

    // };

    function combineArrays(arr1, arr2) {
        const combinedArray = [];

     // Iterate through arr1 and arr2 to combine entries for each tech name
     for (const obj1 of arr1) {
        const techName = obj1.Tech;
        const obj2 = arr2.find((obj) => obj.Tech === techName);
  
        // If obj2 is found for the same tech name, merge their Day entries
        if (obj2) {
          const combinedObj = { Tech: techName };
          for (let i = 1; i <= 31; i++) {
            const dayKey = `Day${i}`;
  
            // Check if attendance clashes for the same date and it's not a "-"
            if (
              obj1[dayKey] !== "-" &&
              obj2[dayKey] !== "-" &&
              obj1[dayKey] !== obj2[dayKey] &&
              (obj1[dayKey].startsWith("W") || obj1[dayKey].startsWith("WS"))
            ) {
              // If attendance clashes, set the value to a special marker (e.g., hex color for highlighting)
              combinedObj[dayKey] = 'Conflict';
            } else {
              // If no clash, retain the original value
              combinedObj[dayKey] = obj1[dayKey] === "-" ? obj2[dayKey] : obj1[dayKey];
            }
          }
          combinedArray.push(combinedObj);
        } else {
          // If obj2 is not found, add obj1 as it is to the combined array
          combinedArray.push(obj1);
        }
      }

        // Add remaining objects from arr2 which are not present in arr1
        for (const obj2 of arr2) {
            const techName = obj2.Tech;
            if (!combinedArray.some((obj) => obj.Tech === techName)) {
                combinedArray.push(obj2);
            }
        }

        return combinedArray;
    }

    const projectdetails = combineArrays(projectdetailso, projectdetailsw);
    projectdetails.sort((a, b) => {
        if (a.Tech < b.Tech) {
            return -1;
        }
        if (a.Tech > b.Tech) {
            return 1;
        }
        return 0;
    });

    function calculateWorkDaysForMonth(arr, month) {
        let totalWorkDays = 0;
        let hasConflict = false; // Flag to check if any day has Conflict
    
        arr.forEach((person) => {
          for (let i = 1; i <= 31; i++) {
            const dayValue = person[`Day${i}`].trim();
            if (dayValue == 'Conflict') {
              hasConflict = true; // Set flag to true if any day has Conflict
              break; // Exit loop if Conflict is found
            } else if ((dayValue.startsWith("W") || dayValue.startsWith("WS"))) {
              totalWorkDays++;
            }
          }
        });
    
        // Return 'Conflict' if any day has Conflict, otherwise return the calculated totalWorkDays
        return hasConflict ? 'Conflict' : totalWorkDays;
      }

    function calculateTravelDaysForMonth(arr, month) {
        let totalTravelDays = 0;
        arr.forEach((person) => {
            for (let i = 1; i <= 31; i++) {
                const dayValue = person[`Day${i}`].trim();
                // Check if the day value includes 'T' anywhere
                if (month === parseInt(i.toString()) && dayValue.startsWith("T")) {
                    totalTravelDays++;
                }
            }
        });
        return totalTravelDays;
    }

    function calculateStandbyDaysForMonth(arr, month) {
        let totalStandbyDays = 0;
        arr.forEach((person) => {
            for (let i = 1; i <= 31; i++) {
                const dayValue = person[`Day${i}`].trim();
                if (month === parseInt(i.toString()) && dayValue.startsWith("S")) {
                    totalStandbyDays++;
                }
            }
        });
        return totalStandbyDays;
    }

    // Function to get all project names
    function getAllProjectNames(arr, month) {
        const projectNames = new Set();
        arr.forEach((person) => {
            var flag = false;
            for (let i = 1; i <= 31; i++) {
                const dayValue = person[`Day${i}`].trim();
                if (dayValue === "WS" || (dayValue.startsWith("S") && (dayValue.split("(")[1].split(")")[0]) === 'WS') || (dayValue.startsWith("T") && (dayValue.split("(")[1].split(")")[0]) === 'WS')) {
                    flag = true;
                }
                // Check for work (W), standby (S), or travel (T) days with non-empty values
                if (dayValue.startsWith("W") || dayValue.startsWith("S") || dayValue.startsWith("T")) {
                    if (dayValue.length > 2) {  // Ensure dayValue has content beyond the prefix (W, S, or T)
                        const projectName = dayValue.split("(")[1].split(")")[0];
                        projectNames.add(projectName);
                    }
                }
            }
            if (flag === true) {
                projectNames.add("WS");
            }
        });
        console.log(projectNames);
        if (projectNames.size === 0) {
            return ["-"];
        } else {
            return Array.from(projectNames).join(", ");
        }
    }

    const daysInMonth = (month, year) => { // Function to determine days in a month
        return new Date(year, month + 1, 0).getDate();
    };
    const calculateRetainerDays = (person, month, year, retainerarray, attendanceData) => {

        if (!attendanceData || !attendanceData.length) {
            console.error("Attendance data is empty or undefined.");
            return "-";
        }

        const retainerStatus = retainerarray[person.Tech]; // Access retainer status using person's tech as key

        // Get the attendance data for the person
        const personAttendance = attendanceData.find((entry) => entry.Tech === person.Tech);

        // Count days with empty strings or "-" for retainers that count absences
        let retainerDays = 0;
        for (let i = 1; i <= daysInMonth(month, year); i++) {
            const dayValue = personAttendance[`Day${i}`]?.trim() || "";

            if (dayValue === "-" || dayValue === "") {
                if (["Monthly-Fixed", "Daily-Retainer", "Retainer"].includes(retainerStatus)) {
                    retainerDays++;
                }
            }
        }

        // Handle different retainership statuses
        if (retainerStatus === "Non-retainer" || retainerStatus === "Salaried") {
            return 0;
        } else if (["Monthly-Fixed", "Daily-Retainer", "Retainer"].includes(retainerStatus)) {
            return retainerDays;
        } else {
            return '-';
        }
    };


    //   const totalWorkDays = calculateWorkDaysForMonth([person], parseInt(valmonth) + 1); // Pass the filtered data for the month
    //   const totalTravelDays = calculateTravelDays(projectdetails, valmonth);
    //   const totalStandbyDays = calculateStandbyDays(projectdetails, valmonth);
    //   const allProjectNames = getAllProjectNames(projectdetails, valmonth);

    return (
        <div>
            <div className={styles.vishdiv}>
                <div className={styles.drophead}>Filter:</div>
                <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>
                    <div>
                        <select
                            className={styles.dropdown}
                            onChange={handlevalChange}
                            value={valmonth}
                            id="month">
                            <option value="0" className={styles.dd}>
                                January
                            </option>
                            <option value="1" className={styles.dd}>
                                February
                            </option>
                            <option value="2" className={styles.dd}>
                                March
                            </option>
                            <option value="3" className={styles.dd}>
                                April
                            </option>
                            <option value="4" className={styles.dd}>
                                May
                            </option>
                            <option value="5" className={styles.dd}>
                                June
                            </option>
                            <option value="6" className={styles.dd}>
                                July
                            </option>
                            <option value="7" className={styles.dd}>
                                August
                            </option>
                            <option value="8" className={styles.dd}>
                                September
                            </option>
                            <option value="9" className={styles.dd}>
                                October
                            </option>
                            <option value="10" className={styles.dd}>
                                November
                            </option>
                            <option value="11" className={styles.dd}>
                                December
                            </option>
                        </select>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{
                                marginLeft: "160px",
                                marginTop: "-95px",
                                cursor: "pointer",
                            }}>
                            onClick=
                            {() => {
                                document.getElementById("designation").click();
                            }}
                            <path
                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                fill="white"></path>
                        </svg>
                    </div>

                    <div style={{ marginLeft: "20px" }}>
                        <select
                            className={styles.dropdown}
                            onChange={handlevalChange2}
                            value={val}
                            id="year">
                            <option value="2026" className={styles.dd}>
                                2026
                            </option>
                            <option value="2025" className={styles.dd}>
                                2025
                            </option>
                            <option value="2024" className={styles.dd}>
                                2024
                            </option>
                            <option value="2023" className={styles.dd}>
                                2023
                            </option>
                            <option value="2022" className={styles.dd}>
                                2022
                            </option>
                            <option value="2021" className={styles.dd}>
                                2021
                            </option>
                            <option value="2020" className={styles.dd}>
                                2020
                            </option>
                        </select>
                        <div
                            style={{ position: "relative", display: "inline-block" }}
                            onClick={() => {
                                document.getElementById("year").click();
                            }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                style={{
                                    marginLeft: "160px",
                                    marginTop: "-95px",
                                    cursor: "pointer",
                                }}>
                                <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="white"></path>
                            </svg>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "50px",
                        justifyContent: "flex-start",
                        marginLeft: "auto",
                    }}>
                    <div
                        className={styles.searchContainer}
                        style={{ position: "relative" }}>
                        <input
                            type="text"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={handleSearchName}
                            className={styles.searchBox}
                        />
                        <svg
                            className={styles.searchIconFilter}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                                fill="#BCBCBC"
                            />
                            <path
                                d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                                fill="#BCBCBC"
                            />
                        </svg>
                    </div>
                </div>
                <div className={styles.dwn} style={{ marginLeft: "auto" }}>
                    {/* <button onClick={handledwn3} className={styles.excelbtn}>
            Download 
          </button> */}
                    {
                        <svg
                            width="40"
                            height="30"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={handledwn3}
                            className={styles.btnfilter3}>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z"
                                fill="#5B6BE1"
                            />
                        </svg>
                    }
                </div>
            </div>

            <div>
                <form id="formm">
                    <div
                        style={{
                            overflowY: "scroll",
                            maxWidth: "97%",
                            marginLeft: "1.5%",
                        }}>
                        {valmonth !== "" && val !== "" && (
                            <table className={styles.rigtable}>
                                <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Techs</th>
                                        <th>Designation</th>
                                        <th>Work days</th>
                                        <th>Travel days</th>
                                        <th>Standby days</th>
                                        <th>Retainer days</th>
                                        <th>Projects</th>
                                        <th>1</th>
                                        <th>2</th>
                                        <th>3</th>
                                        <th>4</th>
                                        <th>5</th>
                                        <th>6</th>
                                        <th>7</th>
                                        <th>8</th>
                                        <th>9</th>
                                        <th>10</th>
                                        <th>11</th>
                                        <th>12</th>
                                        <th>13</th>
                                        <th>14</th>
                                        <th>15</th>
                                        <th>16</th>
                                        <th>17</th>
                                        <th>18</th>
                                        <th>19</th>
                                        <th>20</th>
                                        <th>21</th>
                                        <th>22</th>
                                        <th>23</th>
                                        <th>24</th>
                                        <th>25</th>
                                        <th>26</th>
                                        <th>27</th>
                                        <th>28</th>
                                        {valmonth !== "1" ||
                                            (valmonth === "1" &&
                                                ((val % 4 === 0 && val % 100 !== 0) || val % 400 === 0
                                                    ? true
                                                    : false)) ? (
                                            <th>29</th>
                                        ) : null}
                                        {valmonth !== "1" ? <th>30</th> : null}
                                        {["0", "2", "4", "6", "7", "9", "11"].includes(valmonth) ? (
                                            <th>31</th>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectdetails.map((person, index) => {
                                        // const filteredDataForMonth = projectdetails.filter(entry => entry.Tech === person.Tech);
                                        //  const totalWorkDays = calculateWorkDays(filteredDataForMonth);
                                        //  const totalTravelDays = calculateTravelDays(filteredDataForMonth);
                                        //  const totalStandbyDays = calculateStandbyDays(filteredDataForMonth);
                                        //  const allProjectNames = getAllProjectNames(filteredDataForMonth);
                                        // console.log(filteredDataForMonth)
                                        // if(desarray[person.Tech]){
                                        const totalWorkDays = calculateWorkDaysForMonth(
                                            [person],
                                            parseInt(valmonth) + 1
                                        );

                                        // Loop through all days (1-31) to find travel days in any month
                                        let totalTravelDays = 0;
                                        let totalStandbyDays = 0;
                                        for (let i = 1; i <= 31; i++) {
                                            const dayValue = person[`Day${i}`].trim();
                                            if (dayValue.startsWith("T")) {
                                                totalTravelDays++;
                                            } else if (dayValue.startsWith("S")) {
                                                totalStandbyDays++;
                                            }
                                        }

                                        const allProjectNames = getAllProjectNames([person], parseInt(valmonth));
                                        // const retainerarray = retainerships(person.Tech); // Replace with your logic to fetch retainership status
                                        const totalRetainerDays = calculateRetainerDays(person, parseInt(valmonth), parseInt(val), retainerarray, projectdetails);




                                        return (
                                            <tr key={index}>

                                            <td>{index + 1}</td>
                                            <td>{person.Tech}</td>
                                            <td>{desarray[person.Tech]}</td>
                                            <td style={{
                                              backgroundColor: totalWorkDays === 'Conflict' ? '#FF6A6A' : 'white',
                                              color: totalWorkDays === 'Conflict' ? 'white' : '#006400',fontWeight: 'bold'
                                            }}>{totalWorkDays}</td>
                    
                                            <td style={{ color: '#006400', fontWeight: 'bold' }}>{totalTravelDays}</td>
                                            <td style={{ color: '#006400', fontWeight: 'bold' }}>{totalStandbyDays}</td>
                                            <td style={{ color: '#006400', fontWeight: 'bold' }}>{totalRetainerDays}</td>
                                            <td style={{ color: '#006400', fontWeight: 'bold' }}>{allProjectNames}</td>
                    
                                            <td style={{ backgroundColor: person.Day1 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day1 === 'Conflict' ? 'white' : '#567191' }}>{person.Day1}</td>
                                            <td style={{ backgroundColor: person.Day2 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day2 === 'Conflict' ? 'white' :'#567191' }}>{person.Day2}</td>
                                            <td style={{ backgroundColor: person.Day3 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day3 === 'Conflict' ? 'white' :'#567191' }}>{person.Day3}</td>
                    
                                            {/* Days 4-6 */}
                                            <td style={{ backgroundColor: person.Day4 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day4 === 'Conflict' ? 'white' : '#567191' }}>{person.Day4}</td>
                                            <td style={{ backgroundColor: person.Day5 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day5 === 'Conflict' ? 'white' : '#567191' }}>{person.Day5}</td>
                                            <td style={{ backgroundColor: person.Day6 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day6 === 'Conflict' ? 'white' : '#567191' }}>{person.Day6}</td>
                    
                                            {/* Days 7-9 */}
                                            <td>{person.Day7}</td>
                                            <td style={{ backgroundColor: person.Day8 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day8 === 'Conflict' ? 'white' : '#567191' }}>{person.Day8}</td>
                                            <td style={{ backgroundColor: person.Day9 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day9 === 'Conflict' ? 'white' : '#567191' }}>{person.Day9}</td>
                    
                                            {/* Days 10-12 */}
                                            <td style={{ backgroundColor: person.Day10 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day10 === 'Conflict' ? 'white' : '#567191' }}>{person.Day10}</td>
                                            <td style={{ backgroundColor: person.Day11 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day11 === 'Conflict' ? 'white' : '#567191' }}>{person.Day11}</td>
                                            <td style={{ backgroundColor: person.Day12 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day12 === 'Conflict' ? 'white' : '#567191' }}>{person.Day12}</td>
                    
                                            {/* Days 13-15 */}
                                            <td style={{ backgroundColor: person.Day13 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day13 === 'Conflict' ? 'white' : '#567191' }}>{person.Day13}</td>
                                            <td style={{ backgroundColor: person.Day14 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day14 === 'Conflict' ? 'white' : '#567191' }}>{person.Day14}</td>
                                            <td style={{ backgroundColor: person.Day15 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day15 === 'Conflict' ? 'white' : '#567191' }}>{person.Day15}</td>
                    
                                            {/* Days 16-18 */}
                                            <td style={{ backgroundColor: person.Day16 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day16 === 'Conflict' ? 'white' : '#567191' }}>{person.Day16}</td>
                                            <td style={{ backgroundColor: person.Day17 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day17 === 'Conflict' ? 'white' : '#567191' }}>{person.Day17}</td>
                                            <td style={{ backgroundColor: person.Day18 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day18 === 'Conflict' ? 'white' : '#567191' }}>{person.Day18}</td>
                    
                                            {/* Days 19-21 */}
                                            <td style={{ backgroundColor: person.Day19 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day19 === 'Conflict' ? 'white' : '#567191' }}>{person.Day19}</td>
                                            <td style={{ backgroundColor: person.Day20 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day20 === 'Conflict' ? 'white' : '#567191' }}>{person.Day20}</td>
                                            <td style={{ backgroundColor: person.Day21 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day21 === 'Conflict' ? 'white' :'#567191' }}>{person.Day21}</td>
                    
                                            {/* Days 22-24 */}
                                            <td style={{ backgroundColor: person.Day22 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day22 === 'Conflict' ? 'white' : '#567191' }}>{person.Day22}</td>
                                            <td style={{ backgroundColor: person.Day23 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day23 === 'Conflict' ? 'white' : '#567191' }}>{person.Day23}</td>
                                            <td style={{ backgroundColor: person.Day24 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day24 === 'Conflict' ? 'white' : '#567191' }}>{person.Day24}</td>
                    
                                            {/* Days 25-27 */}
                                            <td style={{ backgroundColor: person.Day25 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day25 === 'Conflict' ? 'white' : '#567191'}}>{person.Day25}</td>
                                            <td style={{ backgroundColor: person.Day26 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day26 === 'Conflict' ? 'white' : '#567191' }}>{person.Day26}</td>
                                            <td style={{ backgroundColor: person.Day27 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day27 === 'Conflict' ? 'white' : '#567191' }}>{person.Day27}</td>
                    
                                            {/* Days 28-30 */}
                                            <td style={{ backgroundColor: person.Day28 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day28 === 'Conflict' ? 'white' : '#567191' }}>{person.Day28}</td>
                    
                                            {valmonth !== "1" ||
                                              (valmonth === "1" &&
                                                ((val % 4 === 0 && val % 100 !== 0) || val % 400 === 0
                                                  ? true
                                                  : false)) ? (
                                              <td style={{ backgroundColor: person.Day29 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day29 === 'Conflict' ? 'white' : '#567191' }}>{person.Day29}</td>
                                            ) : null}
                                            {valmonth !== "1" ? <td style={{ backgroundColor: person.Day30 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day30 === 'Conflict' ? 'white' : '#567191' }}>{person.Day30}</td> : null}
                                            {["0", "2", "4", "6", "7", "9", "11"].includes(
                                              valmonth
                                            ) ? (
                                              <td style={{ backgroundColor: person.Day31 === 'Conflict' ? '#FF6A6A' : 'white', color: person.Day31 === 'Conflict' ? 'white' : '#567191' }}>{person.Day31}</td>
                                            ) : null}
                                          </tr>
                                        );
                                    }
                                        // }
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Attendance;
