import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../Components/css/rigdetails.module.css";
import "../Components/css/toolsregister.css";
import "../Components/css/adding.css";
import UploadDocument from "./Uploaddocument";
import "../Components/css/legaldetails.css";
import EditLegalDetails from "../Components/Editlegaldetails";
import { NavLink } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const LegalDetails = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedRigId, setSelectedRigId] = useState(null);
  const [featurecn, setfeaturecn] = useState([]);
  const [featureedit, setfeatureedit] = useState([]);
  const [showForm, setShowForm] = useState(false);
  // const [formData, setFormData] = useState()
  const [formData, setFormData] = useState({
    s_no: "",
    country: "",
    File_name_for_legal_requirements: "",
  });

  const [refresh, setRefresh] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    documents: null,
  });

  const [selectedFileName, setSelectedFileName] = useState({
    documents: "",
  });

  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      // console.log(res.data);
      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  //adding button functionality
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      s_no: formData.length + 1,
    });
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const filgroupfeatures = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Legal details" && el.Feature_Name === "Add New" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        const filgroupfeatures2 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Legal details" && el.Feature_Name === "Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        setfeaturecn(filgroupfeatures);
        setfeatureedit(filgroupfeatures2);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowForm(false);
    const { country, File_name_for_legal_requirements } = formData;
    const payload = {
      country: country,
      File_name_for_legal_requirements: File_name_for_legal_requirements,
      Documents: selectedFile.documents,
      Document_Names: selectedFileName.documents,
    };
    try {
      const response = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/post1",
        payload
      );

      console.log(response.data); // Access the response data directly
      setFormData({
        // s_no: "",
        country: "",
        File_name_for_legal_requirements: "",
      });
      setSelectedFile({
        documents: null,
      });
      setSelectedFileName({
        documents: "",
      });
    } catch (error) {
      console.log(error);
    }
    setRefresh(!refresh);
  };
  useEffect(() => {
    async function fetchLegalDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/legal_details"
        );
        setSearchResults(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchLegalDetails();
  }, [refresh]);

  const handleEditClick = () => { };

  const handleListClick = () => {
    // Render a list view of the rigs
  };

  const handleMapViewClick = () => {
    // Render a map view of the rigs
  };

  const handleHandbookClick = () => {
    // Render a handbook of the rigs
  };

  const handleDetailsClick = (rig) => {
    console.log(rig);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  //edit functionality
  const [enableEdit, setEnableEdit] = useState(false);

  return (
    <div className={styles.riglegal}>
      <div style={{ marginTop: "-100px" }}>
        <h1 className="tools-register-heading-legal">Legal Details</h1>
        <div className="toolssearchbox-legal">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div btn-group>
        {!enableEdit && (
           featurecn.length > 0 && (<button
            className="addnewbuttontool"
            onClick={() => setShowForm(true)}>
            Add New
            <svg
              width="30"
              height="20"
              viewBox="-5 0 30 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.493 1.66663H6.50964C3.4763 1.66663 1.66797 3.47496 1.66797 6.50829V13.4833C1.66797 16.525 3.4763 18.3333 6.50964 18.3333H13.4846C16.518 18.3333 18.3263 16.525 18.3263 13.4916V6.50829C18.3346 3.47496 16.5263 1.66663 13.493 1.66663ZM15.0013 10.625H10.6263V15C10.6263 15.3416 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3416 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3416 4.3763 9.99996C4.3763 9.65829 4.65964 9.37496 5.0013 9.37496H9.3763V4.99996C9.3763 4.65829 9.65964 4.37496 10.0013 4.37496C10.343 4.37496 10.6263 4.65829 10.6263 4.99996V9.37496H15.0013C15.343 9.37496 15.6263 9.65829 15.6263 9.99996C15.6263 10.3416 15.343 10.625 15.0013 10.625Z"
                fill="white"
              />
            </svg>
          </button>)
        )}
        {!enableEdit ? (
          featureedit.length > 0 && (<button
            className="editbuttontool"
            onClick={() => setEnableEdit(!enableEdit)}>
            Edit
            <svg
              width="35"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                fill="black"
              />
              <path
                d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z"
                fill="black"
              />
              <path
                d="M13.007 9.60829C12.7653 9.49163 12.532 9.37496 12.307 9.24163C12.1236 9.13329 11.9486 9.01663 11.7736 8.89163C11.632 8.79996 11.4653 8.66663 11.307 8.53329C11.2903 8.52496 11.232 8.47496 11.1653 8.40829C10.8903 8.17496 10.582 7.87496 10.307 7.54163C10.282 7.52496 10.2403 7.46663 10.182 7.39163C10.0986 7.29163 9.95697 7.12496 9.83197 6.93329C9.73197 6.80829 9.6153 6.62496 9.50697 6.44163C9.37364 6.21663 9.25697 5.99163 9.1403 5.75829C8.98734 5.43051 8.55713 5.33314 8.30135 5.58891L3.6153 10.275C3.50697 10.3833 3.40697 10.5916 3.38197 10.7333L2.93197 13.925C2.84864 14.4916 3.00697 15.025 3.35697 15.3833C3.65697 15.675 4.07364 15.8333 4.52364 15.8333C4.62364 15.8333 4.72364 15.825 4.82364 15.8083L8.02364 15.3583C8.17364 15.3333 8.38197 15.2333 8.48197 15.125L13.1758 10.4312C13.4264 10.1805 13.3322 9.74924 13.007 9.60829Z"
                fill="black"
              />
            </svg>
          </button>)
        ) : (
          <button
            className="editbuttontool"
            onClick={() => setEnableEdit(!enableEdit)}>
            Cancel
          </button>
        )}
      </div>

      <div>
        {showForm && (
          <div className={styles.popupContainer}>
            <div className={styles.popUp}>
              <form onSubmit={handleSubmit}>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <label className={styles.popupItemss}>
                  Country:
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  File_name_for_
                  <br></br>
                  legal_requirements :
                  <input
                    type="text"
                    name="File_name_for_legal_requirements"
                    value={formData.File_name_for_legal_requirements}
                    onChange={handleInputChange}
                  />
                </label>
                <div className={styles.popupItemss}>
                  <label>
                    Documents:
                    <br />
                    (Upload Document)
                  </label>
                  <div>
                    <UploadDocument
                      fieldName={"documents"}
                      selectedFile={selectedFile.documents}
                      selectedFileName={selectedFileName.documents}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                </div>

                <br />
                <button className={styles.SubmitButton}>Submit</button>
                <button
                  onClick={() => setShowForm(false)}
                  className={styles.CancelButton}>
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>

      <table className={styles.rigtable}>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Country</th>
            <th>File_name_for_legal_requirements </th>
            <th>Documents</th>
          </tr>
        </thead>
        <tbody>
          {searchResults
            .filter((result) =>
              result.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
              result.File_name_for_legal_requirements.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((legal_details, index) => (
              <tr key={legal_details.s_no}>
              <td className="toolsEditRadioBtnEnabbled">
                {enableEdit && (
                  <>
                    <EditLegalDetails
                      setEnableEdit={setEnableEdit}
                      enableEdit={enableEdit}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      s_no={legal_details.s_no}
                      country={legal_details.country}
                        File_name_for_legal_requirements={legal_details.File_name_for_legal_requirements}
                      Documents={legal_details.Documents}
                      Document_Names={legal_details.Document_Names}
                    />
                  </>
                )}
                {index + 1}
              </td>
              <td>{legal_details.country}</td>
              <td>{legal_details.File_name_for_legal_requirements}</td>
              <td>
                {legal_details.Documents ? (
                  <NavLink
                    to={legal_details.Documents}
                    target="_blank"
                    rel="noopener noreferrer">
                      View File
                  </NavLink>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LegalDetails;
