import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../Components/css/monthmandays.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { saveAs } from "file-saver";

// import 'jspdf-autotable';
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
var currentDate = new Date();
function Monthmandays() {

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        whiteSpace: 'pre-wrap', // Allow content to break at any space or newline
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 3, // Limit to 3 lines
        '-webkit-box-orient': 'vertical',
    },
  }));
  const [val, setval] = useState("");
  const [valmonth, setvalmonth] = useState("");
  const [projectdetails, setprojectdetails] = useState([]);


  const [fill, setfill] = useState("false");
  const [Tracker, setTracker] = useState(1);

  const navigate = useNavigate();
  const presentdate = new Date();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    const y = currentDate.getFullYear().toString();
    const m = currentDate.getMonth().toString();
    setval(y);
    setvalmonth(m);
  }, []); // Empty dependency array to run the effect only once

  const monthList = [
    "01", // January
    "02", // February
    "03", // March
    "04", // April
    "05", // May
    "06", // June
    "07", // July
    "08", // August
    "09", // September
    "10", // October
    "11", // November
    "12", // December
  ];
  const handlevalChange = (event) => {
    const selectedval = event.target.value;

    setvalmonth(selectedval);

    setfill("false");
  };
  const handlevalChange2 = (event) => {
    const selectedval = event.target.value;

    setval(selectedval);
    setfill("false");
  };
  const TrackerTab = (tabNumber) => {
    setTracker(tabNumber);
    if(tabNumber === 1){
      navigate('/monthmandays');
    } else if (tabNumber === 2){
      navigate('/yearmandays');
    }
  };


const fetchProject = async () => {
  try {
    const res = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysoffshore?month=${monthList[valmonth]}&year=${val}`
    );

    // const res3 = await axios.get(
    //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopw?month=${monthList[valmonth]}&year=${val}`
    // );

    // const res4 = await axios.get(
    //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/mandaysworkshopt?month=${monthList[valmonth]}&year=${val}`
    // );

    const resu = res.data.results;
    // const resu1 = res3.data.results;
    //  const resu3 = res4.data.results;

    console.log("Resu - Offshore");
    console.log(resu);


//     console.log("Resu1 - Workshop");

//     var transformedArray1 =[];
//     transformedArray1 = resu1.map(({ Rig_Name, Mandays }) => ({
//       Rig_Name,
//       SO_No: '-',  
//       Mandays
//   }));
//   console.log(transformedArray1);


//      console.log("Resu3 - Workshop Travel");
//      var transformedArray2 =[];

//   transformedArray2 = resu3.map(({ Rig_Name, Mandays }) => ({
//     Rig_Name,
//     SO_No: '-',  // Add SO_No with value '-'
//     Mandays
// }));
// console.log(transformedArray2);
// const combinedArray = transformedArray1.concat(transformedArray2);
// const result = combinedArray.reduce((acc, item) => {
//   // Create a unique key for each combination of Rigname and SO_No
//   const key = `${item.Rigname}-${item.SO_No}`;

//   // If the key doesn't exist, create a new entry in the accumulator
//   if (!acc[key]) {
//       acc[key] = { ...item };  // Copy the current item
//   } else {
//       // If the key exists, add the Mandays
//       acc[key].Mandays += item.Mandays;
//   }
//   return acc;
// }, {});
// const finalArray = Object.values(result);
const concatenatedArray = [
  ...(resu ),
  // ...(finalArray)
  
];
concatenatedArray.sort((a, b) => {
  // Comparing string values of Rigname
  if (a.Rig_Name < b.Rig_Name) {
      return -1; // a comes first
  }
  if (a.Rig_Name > b.Rig_Name) {
      return 1; // b comes first
  }
  return 0; // a and b are equal
});
setprojectdetails(concatenatedArray);
} catch (error) {
    console.error("Error fetching project data:", error);
  }
};

  useEffect(() => {
    if (val && valmonth) {
        fetchProject();
      }
  }, [ valmonth, val]);

  const handledwn3 = () => {
    const form = document.getElementById("formm");
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  };





  return (
    <div>
      <div className={styles.vishdiv}>
      {Tracker === 1 && (
          <>
        <div
          style={{
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            marginLeft: "20px",
            marginTop:"17.5px"
          }}
          className={
            Tracker === 1 ? styles.aryaTabs1 : styles.aryaTabs2
          }
          onClick={() => TrackerTab(1)}>
          Monthly
        </div>
        <div
          style={{
            borderBottomRightRadius: "10px",
            borderTopRightRadius: "10px",
            marginLeft: "-16px",
            marginTop:"17.5px"
          }}
          className={
           Tracker === 2 ? styles.aryaTabs1 : styles.aryaTabs2
          }
          onClick={() => TrackerTab(2)}>
          Yearly
        </div>
            {/* <label className={styles.dropdiv2} > Select Project Status:</label>
            <select className={styles.dropdiv3} value={stats} onChange={handlestatChange} id="svgdwn" style={{ cursor: "pointer" }}>
              <option value="Ongoing" className={styles.drop}>Ongoing</option>
              <option value="Recently_Completed" className={styles.drop}>Recently Completed</option>
              <option value="Upcoming" className={styles.drop}>Upcoming</option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                marginLeft: "-50px",
                cursor: "pointer"
              }}
              onClick={() => {
                document.getElementById("svgdwn").click();
              }}

            >
              <path
                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                fill="#394759"
              />
            </svg> */}
          </>
        )}
        <div className={styles.drophead}>Filter:</div>
        <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>
          <div>
            <select
              className={styles.dropdown}
              onChange={handlevalChange}
              value={valmonth}
              id="month">
              <option value="0" className={styles.dd}>
                January
              </option>
              <option value="1" className={styles.dd}>
                February
              </option>
              <option value="2" className={styles.dd}>
                March
              </option>
              <option value="3" className={styles.dd}>
                April
              </option>
              <option value="4" className={styles.dd}>
                May
              </option>
              <option value="5" className={styles.dd}>
                June
              </option>
              <option value="6" className={styles.dd}>
                July
              </option>
              <option value="7" className={styles.dd}>
                August
              </option>
              <option value="8" className={styles.dd}>
                September
              </option>
              <option value="9" className={styles.dd}>
                October
              </option>
              <option value="10" className={styles.dd}>
                November
              </option>
              <option value="11" className={styles.dd}>
                December
              </option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                marginLeft: "160px",
                marginTop: "-95px",
                cursor: "pointer",
              }}>
              onClick=
              {() => {
                document.getElementById("designation").click();
              }}
              <path
                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                fill="white"></path>
            </svg>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <select
              className={styles.dropdown}
              onChange={handlevalChange2}
              value={val}
              id="year">
              <option value="2026" className={styles.dd}>
                2026
              </option>
              <option value="2025" className={styles.dd}>
                2025
              </option>
              <option value="2024" className={styles.dd}>
                2024
              </option>
              <option value="2023" className={styles.dd}>
                2023
              </option>
              <option value="2022" className={styles.dd}>
                2022
              </option>
              <option value="2021" className={styles.dd}>
                2021
              </option>
              <option value="2020" className={styles.dd}>
                2020
              </option>
            </select>
            <div
              style={{ position: "relative", display: "inline-block" }}
              onClick={() => {
                document.getElementById("year").click();
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  marginLeft: "160px",
                  marginTop: "-95px",
                  cursor: "pointer",
                }}>
                <path
                  d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                  fill="white"></path>
              </svg>
            </div>
          </div>
        </div>


        <div className={styles.dwn} style={{ marginLeft: "auto" }}>
          {/* <button onClick={handledwn3} className={styles.excelbtn}>
            Download 
          </button> */}
          {
            <svg
              width="40"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handledwn3}
              className={styles.btnfilter3}>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z"
                fill="#5B6BE1"
              />
            </svg>
          }
        </div>
      </div>

      <div>
        <form id="formm">
          <div
            style={{
              overflowY: "scroll",
              maxWidth: "97%",
              marginLeft: "1.5%",
            }}>
            {valmonth !== "" && val !== "" && (
              <table className={styles.rigtable}>
                <thead>
                  <tr>
                    <th>S. No</th>
                    <th>Rig</th>
                    <th>SO No</th>
                    <th>Man days</th>
                    
                  </tr>
                </thead>
                <tbody>
                <tr >
                <td ><BootstrapTooltip title={"Total Mandays for {selected month}"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                color="black"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                                style={{
                                    cursor: "pointer", marginLeft: "0%",
                                    marginTop: "0%", backgroundColor: "white",
                                    borderRadius:"10px"
                                }}
                            >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                        </BootstrapTooltip></td>
                <td style={{ borderRight: 'none'}}></td>
                        <td style={{  borderLeft: 'none' }}></td>
                        <td  style={{  background: '#D2CFF3' }}>   
                           {projectdetails.reduce((total, rig) => total + parseFloat(rig.Mandays), 0).toFixed(2)}
                         </td>

                      </tr>
                  {projectdetails.map((project, index) => {


                  



                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{project.Rig_Name}</td>
                        <td>{project.SO_No}</td>
                        <td>{parseFloat(project.Mandays)}</td>

                      </tr>
                    );
                  }
                // }
                  )}
                </tbody>
              </table>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Monthmandays;
