import React, { useState,useEffect } from "react";
import axios from "axios";
import UploadDocument from "./Uploaddocument";
import styles from "../Components/css/rigdetails.module.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const EditToolsComponent = ({
  Item_No,
  Description,
  Manufacturer,
  Model,
  Serial_No,
  Cal_Date,
  Due_Date,
  Range_Value,
  Nominal_Value,
  Measured_Value,
  Acceptance_Criteria,
  Frequency,
  Cert_No,
  Status,
  Remarks,
  tools,
  Location,
  Calibration_Verification,
  Calibrated_By,
  Calibration_Doc,
  Reference_Standard,
  Instrument_Type,
  setEnableEdit,
  enableEdit,
}) => {
  const [checked, setChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [toolsData, setToolsData] = useState([]);
  const [updatedData,setUpdatedData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [tempCalibrationFile, setTempCalibrationFile] = useState(null);

  const [formData, setFormData] = useState({
    Item_No: Item_No,
    Description: Description,
    Manufacturer: Manufacturer,
    Model: Model,
    Serial_No: Serial_No,
    Cal_Date: Cal_Date,
    Due_Date: Due_Date,
    Range_Value: Range_Value,
    Nominal_Value: Nominal_Value,
    Measured_Value: Measured_Value,
    Acceptance_Criteria: Acceptance_Criteria,
    Frequency: Frequency,
    Cert_No: Cert_No,
    Status: Status,
    Remarks: Remarks,
    Location: Location,
    Calibration_Verification: Calibration_Verification,
    Calibrated_By: Calibrated_By,  
    Calibration_Doc: Calibration_Doc,
    Reference_Standard: Reference_Standard,
    Instrument_Type: Instrument_Type,  
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

    // Convert yyyy-mm-dd to "%d %M %Y" format
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'long' }); // Full month name
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    };
  
    // Convert "%d %M %Y" back to yyyy-mm-dd for the input field
    const formatDateToInput = (dateString) => {
      const [day, month, year] = dateString.split(' ');
      // Map month name to month number (0-based for JavaScript Date)
      const monthIndex = new Date(`${month} 1, 2000`).getMonth();
      return `${year}-${String(monthIndex + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    };
      
    // Handle the change event for the date picker
    const handleDateChange = (value, fieldName) => {
      // Convert the selected date to "%d %M %Y" format
      const formattedDate = formatDate(value);
  
      // Update the formData state
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: formattedDate,
      }));
    };  

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        Item_No,
        Description,
        Manufacturer,
        Model,
        Serial_No,
        Cal_Date,
        Due_Date,
        Range_Value,
        Nominal_Value,
        Measured_Value,
        Acceptance_Criteria,
        Frequency,
        Cert_No,
        Status,
        Remarks,
        Location,
        Calibration_Verification,
        Calibrated_By,
        Calibration_Doc,
        Reference_Standard,
        Instrument_Type,
      } = formData;
  
      const payload = {
        Item_No:Item_No,
        Description:Description.trim(),
        Manufacturer: Manufacturer,
        Model:Model,
        Serial_No:Serial_No,
        Cal_Date:Cal_Date,
        Due_Date:Due_Date,
        Range_Value:Range_Value,
        Nominal_Value:Nominal_Value,
        Measured_Value:Measured_Value,
        Acceptance_Criteria:Acceptance_Criteria,
        Frequency:Frequency,
        Cert_No:Cert_No,
        Status:Status,
        Remarks:Remarks,
        Location: Location,
        Calibration_Verification: Calibration_Verification,
        Calibrated_By: Calibrated_By,
        Reference_Standard: Reference_Standard,
        Instrument_Type: Instrument_Type,
      };
  
      let fileUrl = null;
  
      // Check if Calibration_Doc is a new file
      if (Calibration_Doc && Calibration_Doc instanceof File) {
        const payloadfiles = new FormData();
        payloadfiles.append("Calibration_Doc", Calibration_Doc);
  
        const resDoc = await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolsregister_upload_calibration_doc?Id_No=${tools}`,
          payloadfiles,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (resDoc.data && resDoc.data.fileUrl) {
          fileUrl = resDoc.data.fileUrl;
        }
      } else {
        // Use the existing file link if no new file is uploaded
        fileUrl = Calibration_Doc;
      }
  
      // Add the file URL to the formData payload if it exists
      if (fileUrl) {
        payload.Calibration_Doc = fileUrl;
      }
  
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatetools/${tools}`,
        payload
      );

      if (res.status === 200) {
        console.log("API response data:", res.data);
        const updatedData = res.data;

        // Update toolsData state with the updated data
        setToolsData((prevData) =>
          prevData.map((tool) =>
            tool.Id_No === tools
              ? { ...tool, ...updatedData, Calibration_Doc: fileUrl }
              : tool
          )
        );

        // Update formData state with the updated data
        setFormData({ ...updatedData });

        // Log updated formData state after setting (using callback)
        setFormData((prevData) => ({ ...prevData, ...updatedData }), () => {
          console.log("Updated formData state after setting:", formData);
        });

        closePopup();
      }
    } catch (error) {
      console.error("Failed to update tools data:", error);
    }
  };
  
  

  useEffect(() => {
    console.log("toolsData state has been updated:", toolsData);
  }, [toolsData,refresh]);
  
  useEffect(() => {
    console.log("formData state has been updated:", formData);
  }, [formData,refresh]);
  

  // Update toolsData whenever formData updates


  // _______________________________to uncheck radio button
  const closePopup = () => {
    setChecked(!checked);
    handleUncheckRadio();
    setEnableEdit(!enableEdit);
  };
  const handleRadioOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleUncheckRadio = () => {
    setSelectedOption("");
  };
  // ______________________________________________________

  const handlefileupload1 = (uploadname, e) => {
    const { name } = e.target
    let tempFile = e.target.files[0];
    console.log(tempFile);
    const inputElement = document.getElementsByName(uploadname)[0];
    const selectedFile = inputElement.files[0];
    setTempCalibrationFile(selectedFile?.name || ""); // Set to blank if no file is selected
    setFormData({ ...formData, [name]: tempFile });
    inputElement.value = "";
    tempFile.value = "";
  }

  return (
    <div>
      <div className={styles.editRadioButtonRig}>
        <input
          type="radio"
          name="rig"
          value="select"
          checked={selectedOption === "select"}
          onChange={handleRadioOptionChange}
          onClick={() => setChecked(!checked)}
        />
      </div>
      {checked && (
        <>
          <div
            className={styles.popupContainer}
            onClick={(e) => {
              e.stopPropagation();
              setChecked(!checked);
              handleUncheckRadio();
            }}>
            <div className={styles.popUp} onClick={handleStopClose}>
              <form onSubmit={handleSubmit}>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <label className={styles.popupItemss}>
                  Item Code :
                  <input
                    type="text"
                    name="Item_No"
                    value={formData.Item_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Equipment Name :
                  <input
                    type="text"
                    name="Description"
                    value={formData.Description}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Make :
                  <input
                    type="text"
                    name="Manufacturer"
                    value={formData.Manufacturer}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Model :
                  <input
                    type="text"
                    name="Model"
                    value={formData.Model}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Traceability :
                  <input
                    type="text"
                    name="Serial_No"
                    value={formData.Serial_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Calibration Date :
                  <input
                    type="date"
                    name="Cal_Date"
                    value={formData.Cal_Date ? formatDateToInput(formData.Cal_Date) : ''}
                    onChange={(e) =>
                      handleDateChange(e.target.value, 'Cal_Date')
                    }
                  />
                </label>
                <label className={styles.popupItemss}>
                  Calibration Due Date :
                  <input
                    type="date"
                    name="Due_Date"
                    value={formData.Due_Date ? formatDateToInput(formData.Due_Date) : ''}
                    onChange={(e) =>
                      handleDateChange(e.target.value, 'Due_Date')
                    }
                  />
                </label>

                <label className={styles.popupItemss}>
                  Range Value :
                  <input
                    type="text"
                    name="Range_Value"
                    value={formData.Range_Value}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Least Count :
                  <input
                    type="text"
                    name="Nominal_Value"
                    value={formData.Nominal_Value}
                    onChange={handleInputChange}
                  />
                </label>
                {/* <label className={styles.popupItemss}>
                  Measured Value :
                  <input
                    type="text"
                    name="Measured_Value"
                    value={formData.Measured_Value}
                    onChange={handleInputChange}
                  />
                </label> */}
                <label className={styles.popupItemss}>
                  Acceptance Criteria :
                  <input
                    type="text"
                    name="Acceptance_Criteria"
                    value={formData.Acceptance_Criteria}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Frequency of Calibration (Days):
                  <input
                    type="text"
                    name="Frequency"
                    value={formData.Frequency}
                    onChange={handleInputChange}
                  />
                </label>
                {/* <label className={styles.popupItemss}>
                  Cert No:
                  <input
                    type="text"
                    name="Cert_No"
                    value={formData.Cert_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Status:
                  <input
                    type="text"
                    name="Status"
                    value={formData.Status}
                    onChange={handleInputChange}
                  />
                </label> */}
                <label className={styles.popupItemss}>
                  Remarks:
                  <input
                    type="text"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Location / Issued to :
                  <input
                    type="text"
                    name="Location"
                    value={formData.Location}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Calibration / Verification:
                  <input
                    type="text"
                    name="Calibration_Verification"
                    value={formData.Calibration_Verification}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Calibrated By :
                  <input
                    type="text"
                    name="Calibrated_By"
                    value={formData.Calibrated_By}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Calibration Document :
                  <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                    {/* Read-only text input for displaying the file name */}
                    <input
                      type="text"
                      value={tempCalibrationFile || (formData.Calibration_Doc
                        ? formData.Calibration_Doc?.toString().split("/").pop().split("-").slice(1).join("-")
                        : "Upload File")}  
                      readOnly
                      style={{
                        flex: 1,
                        paddingRight: "40px",
                        cursor: "pointer",
                      }}
                      onClick={() => document.getElementById("hiddenFileInput").click()}
                    />
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Calibration_Doc"
                      id="hiddenFileInput"
                      onInput={(e) => handlefileupload1("Calibration_Doc", e)}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                    <svg
                      width="28"
                      height="25"
                      viewBox="0 0 28 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="black"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </label>
                <label className={styles.popupItemss}>
                  Reference Standard :
                  <input
                    type="text"
                    name="Reference_Standard"
                    value={formData.Reference_Standard}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Instrument Type :
                  <select
                    name="Instrument_Type"
                    value={formData.Instrument_Type}
                    onChange={handleInputChange}
                    style={{
                      appearance: 'none',
                      backgroundImage: `url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="black" stroke-width="1" d="M0,0 L2,2 L4,0"/></svg>')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 8px bottom 12px',
                      backgroundSize: '0.75em auto',
                      paddingRight: '1.5em',
                    }}                
                  >
                    <option value="">Select Option</option>
                    <option value="Adjustable">Adjustable</option>
                    <option value="Non-Adjustable">Non-Adjustable</option>
                  </select>
                </label>
                <br />
                <button type="submit" className={styles.SubmitButton}>Submit</button>
                <button onClick={closePopup} className={styles.CancelButton}>Cancel</button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditToolsComponent;
