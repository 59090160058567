import React from "react";
import styles from "./css/workshopcreatenew.module.css";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function WorkshopCreateNew() {
  const [formdata1Display1, setFormData1Display1] = useState(true);
  const [isRigNameValid, setIsRigNameValid] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    scopeDescription:"",
    design:"",
    DmccFze:"",
    FzeDmccPo:"",
  });

  const [selectedFile, setSelectedFile] = useState({
    workPlan: null,
  });
  const [selectedFileName, setSelectedFileName] = useState({
    workplan: "",
  });

  const handleNumeriChange = (e) => {
    let { name, value } = e.target;

    // value = value.replace(/[^0-9]/g, "");
    // if (value.length <= 3 && !isNaN(value)) {
    setFormData1({
      ...formdata1,
      [name]: value,
    });
    // }
  };
  let projectId;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDivClick = () => {
    navigate("/workshopprojects");
  }

  const navigate = useNavigate();

  const handleCreateNewProject = async (e) => {
    e.preventDefault();
    const {
       salesOrderNo,

      PONO,
      PODate,
      QuoteNO,
      QuoteDate,
      CostingID,
      ProjectName,
      Scope,
      CustomerName,
      FirstArrival,
      EstimatedDateCommencement,
      TargetDaysForCompletion,
      TargetManhours,
      opsEngineer,
      currentStatus,
      projectDescription,
      quantity,
      scopeDescription,
      design,
      DmccFze,
      FzeDmccPo,
    } = formdata1;

    const payload = {
       Sales_Order_No: salesOrderNo,
      PO_No: PONO,
      PO_Date: PODate,
      Quote_No: QuoteNO,
      Quote_Date: QuoteDate,
      Costing_ID: CostingID,
      Project_Name: ProjectName,
      Scope: Scope,
      Quantity: quantity,
      Customer_Name: CustomerName,
      First_Arrival: FirstArrival,
      Estimated_Date_Of_Commencement: EstimatedDateCommencement,
      Target_Days_For_Completion: TargetDaysForCompletion,
      Target_Manhours: TargetManhours,
      Ops_Engineer: opsEngineer,
      Current_Status: currentStatus,
      Project_Description: projectDescription,
      Scope_Description: scopeDescription,
      Design:design,
      Dmcc_fze:DmccFze,
      Fze_dmcc_po:FzeDmccPo,
    };
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopprojectdetails_post",
        payload
      );
      const projectId = res.data.ID_No;

      const idPayload = {
        ID_No: projectId,
      };

      const checklistPayload = {
        Sales_Order_No: salesOrderNo,
        ID_No: projectId,
      };

      const forChecklist = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopproject_checklist",
        checklistPayload
      );

      if (res.status === 200) {
        alert("project created successfully");
        navigate(`/workshopprojects?id=${projectId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };



  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("DD/MM/YYYY");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };

  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  return (
    <>
      {formdata1Display1 && (
        <div className={styles.sectionscreate}>
          <form>
            <div className={styles.splitHalfSection}>
              <div>
                <div className={styles.formElement}>
                  <label>Sales Order No</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <span className={styles.salesOrderSpan}></span> */}
                    <input
                      type="text"
                      value={formdata1.salesOrderNo}
                      name="salesOrderNo"
                      onChange={handleNumeriChange}
                    />
                  </div>
                </div>

                <div className={styles.formElement}>
                  <label>PO Number</label>
                  <input
                    type="number"
                    min={0}
                    value={formdata1.PONO}
                    name="PONO"
                    onChange={handleOnChange}
                  />
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>PO Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="PODate"
                      value={
                        formdata1.PODate
                          ? dayjs(formdata1.PODate, "DD/MM/YYYY")
                          : null
                      }
                      onChange={(value) => handleDates("PODate", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className={styles.formElement}>
                  <label>Quote no</label>
                  <input
                    type="number"
                    min={0}
                    value={formdata1.QuoteNO}
                    name="QuoteNO"
                    onChange={handleOnChange}
                  />
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Quote Date</label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="QuoteDate"
                      value={
                        formdata1.QuoteDate
                          ? dayjs(formdata1.QuoteDate, "DD/MM/YYYY")
                          : null
                      }
                      onChange={(value) => handleDates("QuoteDate", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className={styles.formElement}>
                  <label>Costing ID</label>
                  <input
                    type="number"
                    min={0}
                    value={formdata1.CostingID}
                    name="CostingID"
                    onChange={handleOnChange}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Customer Name</label>
                  <select
                    value={formdata1.CustomerName}
                    name="CustomerName"
                    onChange={handleOnChange}
                    required>
                    <option value="">Select value</option>
                    <option value="Shelf Drilling">Shelf Drilling</option>
                    <option value="Valaris">Valaris</option>
              <option value="ARO Drilling">ARO Drilling</option>
              <option value="ADES">ADES</option>
              <option value="COC">COC</option>
              <option value="Northern Offshore">Northern Offshore</option>
              <option value="Melius">Melius</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.formElement}>
                  <label style={{marginBottom:"-74px"}}>DMCC-FZE Quote</label>
                  <div style={{ display: "flex", alignItems: "center",marginBottom:"-72px" }}>
                    {/* <span className={styles.salesOrderSpan}></span> */}
                    <input
                      type="text"
                      value={formdata1.DmccFze}
                      name="DmccFze"
                      onChange={handleNumeriChange}
                    />
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label style={{marginBottom:"-150px"}}>FZE-DMCC PO</label>
                  <div style={{ display: "flex", alignItems: "center" ,marginBottom:"-152px"}}>
                    {/* <span className={styles.salesOrderSpan}></span> */}
                    <input
                      type="text"
                      value={formdata1.FzeDmccPo}
                      name="FzeDmccPo"
                      onChange={handleNumeriChange}
                    />
                  </div>
                </div>
              </div>

              <div>

              <div className={styles.formElement}>
                  <label>Design</label>
                  <select
                    value={formdata1.Design}
                    name="Design"
                    onChange={handleOnChange}
                    required
                    className={!isRigNameValid ? styles.highlightError : ""}>
                    <option value="">Select value</option>
                    <option value="MLT">MLT</option>
                        <option value="BMC">BMC</option>
                        <option value="ZPMC">ZPMC</option>
                        <option value="NoV">NoV</option>
                        <option value="Keppel">Keppel</option>
                        <option value="Fairfield">Fairfield</option>
                        <option value="Promoter">Promoter</option>
                    {/* {duplicateRemovedShortName.map((short_name) => (
                    <option key={short_name}>{short_name}</option>
                  ))} */}
                  </select>

                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className={styles.formElement}>
                  <label>Project Name *</label>
                  <select
                    value={formdata1.ProjectName}
                    name="ProjectName"
                    onChange={handleOnChange}
                    required
                    className={!isRigNameValid ? styles.highlightError : ""}>
                        <option value="">Select value</option>
                        <option value="Skidder">Skidder</option>
                        <option value="Anchor Winch">Anchor Winch</option>
                        <option value="Jacking Primary Gearbox">Jacking Primary Gearbox</option>
                        <option value="Jacking Gearbox">Jacking Gearbox</option>
                        <option value="Skidding Primary Gearbox">Skidding Primary Gearbox</option>
                        <option value="Crane Primary Gearbox">Crane Primary Gearbox</option>
                        <option value="Jacking Toolbox">Jacking Toolbox</option>
                        <option value="RWT Gearbox">RWT Gearbox</option>
                        <option value="CycloDrive">CycloDrive</option>
                        <option value="N-Driver">N-Driver</option>
                        <option value="Mud Pumps">Mud Pumps</option>
                        <option value="Spares Trading">Spares Trading</option>
                       
                    {/* {duplicateRemovedShortName.map((short_name) => (
                    <option key={short_name}>{short_name}</option>
                  ))} */}
                  </select>

                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.formElement}>
                  <label>Scope</label>
                  <select
                    value={formdata1.Scope}
                    name="Scope"
                    onChange={handleOnChange}
                    required>
                    <option value="">Select value</option>
                    <option value="Assembly">Assembly</option>
                        <option value="D&I">D&I</option>
                        <option value="Overhaul">Overhaul</option>
                        <option value="Replenishment">Replenishment</option>
                        <option value="Inspection">Inspection</option>
                        <option value="Storage">Storage</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.formElement}>
                  <label>Project Description</label>

                  <input
                    type="text"
                  
                    value={formdata1.projectDescription}
                    name="projectDescription"
                    onChange={handleOnChange}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Quantity</label>

                  <input
                    type="number"
                    min={0}
                    value={formdata1.quantity}
                    name="quantity"
                    onChange={handleOnChange}
                  />
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Date of First Component/Spares arrival</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="FirstArrival"
                      value={
                        formdata1.FirstArrival
                          ? dayjs(formdata1.FirstArrival, "DD/MM/YYYY")
                          : null
                      }
                      onChange={(value) => handleDates("FirstArrival", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                      required
                    />
                  </LocalizationProvider>
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Estimated Date Of Commencement</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="EstimatedDateCommencement"
                      value={
                        formdata1.EstimatedDateCommencement
                          ? dayjs(
                              formdata1.EstimatedDateCommencement,
                              "DD/MM/YYYY"
                            )
                          : null
                      }
                      onChange={(value) =>
                        handleDates("EstimatedDateCommencement", value)
                      }
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                      required
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div>
                <div className={styles.formElement}>
                  <label>Target Days For Completion</label>
                  <input
                    type="number"
                    min={0}
                    value={formdata1.TargetDaysForCompletion}
                    name="TargetDaysForCompletion"
                    onChange={handleOnChange}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Target Manhours</label>
                  <input
                    type="number"
                    min={0}
                    value={formdata1.TargetManhours}
                    name="TargetManhours"
                    onChange={handleOnChange}
                  />
                </div>
                <div className={styles.formElement}>
                  <label>Ops Engineer</label>
                  <select
                    value={formdata1.opsEngineer}
                    name="opsEngineer"
                    onChange={handleOnChange}>
                    <option value=""> Select the Member</option>
                    <option value="Jancy">Jancy</option>
                    <option value="Charulatha">Charulatha</option>
                    <option value="Raniya">Raniya</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.formElement}>
                  <label>Scope Description</label>
                  <input
                    type="text"
                    min={0}
                    value={formdata1.ScopeDescription}
                    name="ScopeDescription"
                    onChange={handleOnChange}
                    className={styles.textboxscopedesc}
                  />
                </div>
                <div className={styles.formElement}>
                  <label className={styles.currentStatus}>Current Status</label>

                  <input
                    type="text"
                    min={0}
                    value={formdata1.currentStatus}
                    name="currentStatus"
                    onChange={handleOnChange}
                    className={styles.textbox1b}
                  />
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              {/* <BasicModal  /> */}
              <Button
                onClick={handleDivClick}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  border: "1px solid #5B6BE1",
                  color: "#5B6BE1",
                  borderRadius: "8px",
                  height: "50px",
                  width: "170px",
                  "&:hover": {
                    borderColor: "#5B6BE1",
                  },
                }}>
                Discard
              </Button>
              <Button
                onClick={handleCreateNewProject}
                variant="contained"
                className={styles.proceedBtn}>
                Create Project
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default WorkshopCreateNew;
